@import '../../../../assets/styles/index.scss';

.user-feedback {
  position: relative;
  width: 281px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  &__close-icon {
    position: absolute;
    top: 7px;
    right: 6px;
    cursor: pointer;
  }
  &__header {
    background: $primary-600;
    padding: 18px 42px;
    border-radius: 5px 5px 0 0;
    cursor: auto;
    &__heading {
      color: #fff;
      text-align: center;
    }
  }
  &__feedback-container {
    padding: 19px 14px 13px 14px;
  }
  &__options {
    display: flex;
    justify-content: space-between;
    padding: 0px 23px 19px 23px;

    &__option {
      img {
        transition: all 0.25s linear;
        border-radius: 50%;
        border: 3px solid rgba(0, 0, 0, 0);
        cursor: pointer;
      }
      p {
        text-align: center;
      }
    }
    &__positive {
      img:hover {
        border: 3px solid lighten($green-3, 50%);
      }
      &--is-active > img {
        border: 3px solid $green-3;
        &:hover {
          border: 3px solid $green-3;
        }
      }
    }
    &__neutral {
      img:hover {
        border: 3px solid lighten($orange-3, 40%);
      }
      &--is-active > img {
        border: 3px solid $orange-3;
        &:hover {
          border: 3px solid $orange-3;
        }
      }
    }
    &__negative {
      img:hover {
        border: 3px solid lighten($red-1, 35%);
      }
      &--is-active > img {
        border: 3px solid $red-1;
        &:hover {
          border: 3px solid $red-1;
        }
      }
    }
  }
  &__feedback {
    margin-bottom: 9px;
    .text-area-container {
      border: 1px solid $light-grey-1;
      height: 105px;
      border-radius: 5px;
      padding: 5px;
      margin-bottom: 2px;
    }
    &__text-area {
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 6px 8px;
      resize: none;
      outline: none;
      font-size: 14px;
      color: $black-800;
      &::placeholder {
        color: $black-800;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      @include customScroll;
    }
    &__feedback-length {
      color: $black-800;
      text-align: right;
    }
  }
  &__submit-feedback {
    width: 100%;
  }
  &__close-icon {
    position: absolute;
  }
}
