@import '../../assets/styles/index.scss';

@mixin titleStyles {
  color: $black-800;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 0.3px;
}
.common-accordion.faq__items__item__accordion:hover {
  background-color: $primary-100;
}
.common-accordion.faq__items__item__accordion:hover .common-paper-custom {
  background-color: $primary-100;
}

.faq_search_box {
  width: 400px !important;
}
.container_FAQ {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  margin-bottom: 16px;
}
.modal {
  position: fixed;
  top: 0;
  right: -100%; /* Start off-screen */
  width: 300px; /* Adjust the width as needed */
  height: 100%;
  background-color: white;
  transition: right 0.3s ease-in-out; /* Animation for sliding in */
}

.modal.open {
  right: 0; /* Slide in when open */
}

.modal-content {
  padding: 20px;
}

p.string {
  line-height: 19.7px;
  font-family: Noto Sans;
  font-weight: 400;
  letter-spacing: normal;
  color: $black-800;
}

.parent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  row-gap: 106px;
}

.child {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}
.twice-img {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.azure_redirect_url {
  color: $primary-700;
  font-weight: 600;
  text-decoration: underline;
}
.azure-tips-img {
  width: 100%;
  margin-bottom: -90px;
}

@media (max-width: 768px) {
  .child {
    width: 100%;
  }
}
.cis_style {
  font-family: lato;
  font-weight: 500;
}

.btn_faq_need_help {
  float: right;
}

.faq {
  height: calc(100vh - 150px);
  padding: 10px;
  .molecule_searchInputField {
    margin-bottom: 14px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    &__item {
      .faq__items__item__accordion {
        padding: 19px 24px;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 8px 30px 0px #1212121a;
        border: 1px solid rgba(255, 255, 255, 0);

        &:hover {
          border-color: #257ad2;
        }
        &__head {
          background: rgba(255, 255, 255, 0);
          border-bottom: none;
          border-radius: 10px;
          padding: 0;

          &__title {
            @include titleStyles;
          }
          &__handler {
            background: none;
          }
          &--is-open {
            background: none;
            margin: 0;
            .title {
              color: $primary-600;
            }
            svg > path {
              stroke: $primary-600;
            }
          }
        }
        &__content {
          @include titleStyles;
          &--is-open {
            margin-top: 19px;
            height: auto;
          }
        }
        &__content:hover {
          background-color: inherit;
        }
        &--is-open {
          border: 1px solid $primary-600;
          box-shadow: 0px 8px 30px 0px #1212121a;
          background: #fff;
          border-radius: 16px;
        }
      }
    }
    &__not-found {
      margin-top: 20px;
      text-align: center;
    }
  }
  &__user-feedback {
    display: none;
    position: fixed !important;
    right: 53px;
    bottom: -4%;
    transform: translateY(-50%);
    &--is-open {
      display: block;
    }
  }
  &--is-loading {
    .apply-loader {
      pointer-events: none;
    }
  }
}

//Style @ Mobile
@media only screen and (max-width: $mobile) {
  .container_FAQ {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .faq_search_box {
    width: 100% !important;
  }
  .faq__items {
    margin-bottom: 25px;
  }
}
