@import '../../assets/styles/index.scss';

.confirmForgotPassword {
  @extend .d-flex;
  @extend .d-flex-col;
  gap: 14px;

  &__field {
    position: relative;

    &__passwordTooltip {
      top: 2rem;
      left: -11.5rem;
    }
    &__eyeIcon {
      position: absolute;
      top: 2.6rem;
      right: 1rem;
      @extend .cursor-pointer;
    }
    &__timer {
      @extend .d-flex;
      @extend .w-full;
      @extend .align-items-center;
      flex-direction: row-reverse;
      justify-content: space-between;

      &__link {
        @extend .cursor-pointer;
        color: $primary-600;
      }
      &__disabledLink {
        cursor: not-allowed;
        color: $primary-400;
      }
    }
  }
}
//Style @ Mobile
@media only screen and (max-width: 600px) {
  .confirmForgotPassword__field__passwordTooltip {
    display: none;
  }
  info_icon {
    display: none;
  }
}
