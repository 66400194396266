@import '../../../../assets/styles/globals/index.scss';

.MuiModal-root#common-confirm-modal {
  display: flex;
}

.slack_reconfigure_modal {
  width: 342px;
  background: #fff;
  outline: none;
  margin: 60px auto auto auto;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  font-family: Noto Sans;
  gap: 12px;
  overflow: hidden;

  .content {
    padding: 27px 30px 12px 30px; //top right bottom left

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: $normal-text;
      font-family: Noto Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;

      .logoNameContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $black-700;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .container {
      margin-top: 27px;
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        width: 100%;
      }

      .subHeading {
        color: $black-700;
      }

      .channel {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .inputField {
        display: flex;
        gap: 8px;
        margin: 12px 0 20px 0;
        color: $black-700;
      }
      .newSlackWorkspace {
        margin: 12px 0 0 0;
      }
      .existingChannelsContainer {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .existingChannels {
          @include customScroll;
          height: 200px;
          overflow-y: auto;
          overflow-x: hidden;

          .accordionClass {
            background-color: #fff;
            box-shadow: none !important;

            .title {
              display: flex;
              width: calc(100% - 10px);
              justify-content: space-between;
            }

            &__head {
              background: inherit;
              border: none;
              display: flex;
              padding-left: 0;
              padding-bottom: 0;
              padding-right: 0;
              margin-bottom: 0;

              &--is-open {
                background: inherit;
              }
              &__handler {
                background: none;
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        gap: 24px;
        padding-top: 20px;
        justify-content: center;
      }
    }
  }

  .note {
    display: flex;
    gap: 9px;
    padding: 16px;
    width: 100%;
    background-color: $blue-2;
    border-radius: 4px;
    color: #fff;

    img {
      align-self: flex-start;
    }
  }
}

.loaderClass {
  .apply-loader {
    @include loaderSkeleton;
  }
}

@media screen and (min-width: $tablet) {
  .slack_reconfigure_modal {
    width: 497px;
    &__image {
      margin-bottom: 21px;
    }
  }
}

.paperClass {
  box-shadow: none;
}
