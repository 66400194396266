@import '../../../assets/styles/index.scss';

.More-info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 54px;
  width: fit-content;
  height: auto;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid $blue-5;
  background: $primary-100;
  color: $blue-5;

  &.warning {
    border-color: $warning-700;
    background: $warning-100;
    color: $warning-700;
  }
}

.More-info-container .inner-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 25px;
}

.header-more-info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.header-more-info h3 {
  font-family: Noto Sans;
  font-size: clamp(12px, 1.05vw, 1.05vw);
  font-weight: 600;
  line-height: normal;
}
.More-info-container p {
  font-family: Noto Sans;
  font-size: clamp(12px, 1vw, 1vw);
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.More-info-container ul {
  margin-left: 17px;
}
.More-info-container ul li {
  font-size: clamp(12px, 1vw, 1vw);
  font-weight: 600;
  list-style: decimal;
}

@media only screen and (min-width: $tablet_lg) {
  .More-info-container {
    width: calc(100% - 138px);
  }
}
