@import '../../../assets/styles/index.scss';
.account {
  padding: 24px;
  display: flex;
  height: calc(100vh - 210px);
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  line-height: normal;
  font-style: normal;
  position: relative;

  &_heading {
    font-family: Noto Sans;
    display: flex;
    gap: 5px;
    font-size: 16px;

    h2 {
      color: $black-1000;
    }
    &__span {
      font-weight: 400;
      color: $black-800;
    }
    span > span {
      color: $primary-600;
      font-weight: 600;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 19px;
    font-size: 14px;

    h4 {
      width: auto;
      color: $black-900;

      .org-name {
        color: $primary-600;
      }
    }

    .steps {
      ol {
        display: flex;
        flex-direction: column;
        gap: 19px;
      }
      ol > li > h4 {
        margin-left: 2px;
        color: $error-600-light;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 19px;
      }
    }
    button {
      width: 220px;
      font-weight: 500;
    }
  }

  &__faq {
    position: fixed !important;
    right: 3.5em;
    bottom: -2%;
    transform: translateY(-50%);
  }
}

.account_loading {
  .apply-loader {
    @include loaderSkeleton;
    display: flex;
    padding: 0 5px;
    width: max-content;
  }
}

@media only screen and (max-width: $mobile) {
  .account_heading {
    flex-direction: column;
    gap: 8px;
  }
}
