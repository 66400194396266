@import '../../assets/styles/index.scss';

.edit-user-access-control {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  padding: 12px 11px;
  &__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    &__buttons {
      display: flex;
      flex-direction: column-reverse;
      column-gap: 13px;
      row-gap: 8px;
      justify-content: space-between;
      width: 100%; // only for mobile
      &__right {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        column-gap: 13px;
      }
      .add-user-to-group {
        @include whiteButton;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        padding: 6px 10px;
        width: 100%;
      }
      .cancel {
        @include whiteButton;
        width: 146px;
      }
      .save {
        @include blueButton;
        width: 147px;
      }
    }
  }
  &__content {
    overflow: visible;
    @include customScroll;
    flex-basis: 100%;
    @include flexCol;
    row-gap: 24px;
    padding: 2px 0;
    .group-name {
      @include flexRow;
      column-gap: 8px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .edit-user-access-control {
    padding: 10px 12px 10px 19px;
    padding: 12px;
    &__head {
      &__buttons {
        width: fit-content;
        padding: 0; // only for tablet onwards
        &__left {
          align-self: flex-end;
        }
        .add-user-to-group {
          width: 174px;
        }
        .cancel {
          width: 134px;
        }
        .save {
          @include blueButton;
          width: 134px;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .edit-user-access-control {
    row-gap: 32px;
    &__head {
      &__buttons {
        width: fit-content;
        flex-direction: row;
        &__left {
          align-self: flex-end;
        }
        &__right {
          column-gap: 13px;
        }
        .add-user-to-group {
          width: 200px;
        }
        .cancel {
          width: 200px;
        }
        .save {
          @include blueButton;
          width: 200px;
        }
      }
    }
  }
}
