@import '../../../assets/styles/index.scss';

.aws-info {
  width: 100%;
  color: $black-800;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}
