@import '../../../assets/styles/index.scss';

.cloud-account-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  &__icon {
    border-radius: 8px;
    background: $primary-100;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--is-disabled {
      opacity: 50%;
    }
    &--delete {
      background: $errors-100;
    }
    svg {
      height: 18px;
      width: 18px;
    }
  }
}
