@import '../../../../assets/styles/index.scss';

.tooltip-custom {
  position: relative;
  width: fit-content;
  &__tooltip {
    @include tooltipTextStyle;
    @include tooltipContainer;
    z-index: 99999999;
    position: fixed;
    height: fit-content;
    text-align: center;
    transition: all 0.25s;
    &--limit-width {
      white-space: break-spaces;
      max-width: 207px;
      word-break: break-word;
    }
  }
}
