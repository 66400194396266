.need_help_btn_container {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}
.forms {
  margin-top: 16px;
}
.need_help_textarea {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #6e6e6e;
  height: 140px !important;
  padding: 12px 8px !important;
  margin-top: 6px;
  color: #1d1d1d;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  resize: none;
}
.need_help_textarea:focus {
  padding: 12px 8px !important;
}
.needhelp-subject {
  padding: 12px 8px;
}
.needhelp-subject:focus {
  padding: 12px 8px;
}

.need_help_textarea:hover {
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
}

.need_help_textarea:focus {
  outline: none;
  border: 1px solid #33475b;
  box-shadow: none;
  outline-offset: 2px;
  padding: 12px 8px;
  padding-inline-end: 35px;
}

.need_help_btn_container {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}
.forms {
  margin-top: 16px;
}
.need_help_textarea:hover {
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
}

.need_help_textarea:focus {
  outline: none;
  border: 1px solid #33475b;
  box-shadow: none;
  outline-offset: 2px;
  padding: 12px 8px;
  padding-inline-end: 35px;
}
.container_uploaded_img {
  display: grid;
  gap: 12px;
}
.uploaded-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-info {
  display: flex;
  align-items: center;
}

.cloud-icon-left {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.max-images-error {
  color: #b91c1c;
}

.file_upload_container {
  padding: 12px 0px 12px 0px;
}

/*Style @Mobile*/
@media only screen and (max-width: 600px) {
  .need_help_textarea {
    height: 120px !important;
  }
  .need_help_btn_container {
    margin-bottom: 90px;
  }
}
