@import '../../../../assets/styles/index.scss';

.add-group-permissions-container {
  @include customScroll;
  @include flexCol;
  overflow-y: auto;
  margin: 18px 11px;
  row-gap: 24px;
  padding: 16px;
  &__go-back {
    display: flex;
    column-gap: 8px;
    cursor: pointer;
    color: $black-1000;
  }
  &__permissions {
    @include customScroll;
    @include flexCol;
    margin-bottom: 27px;
    row-gap: 24px;
  }
  &__navigation {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    background: #fff;
    &__backward {
      display: flex;
      column-gap: 18px;
      .cancel {
        @include whiteButton;
        width: 98px;
      }
      .goback {
        @include whiteButton;
        width: 75px;
        border: none;
        background: none;
        &:hover {
          box-shadow: none;
          background: $light-blue-3;
        }
      }
    }
    &__forward {
      .next {
        @include blueButton;
        width: 98px;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .add-group-permissions-container {
    &__navigation {
      &__backward {
        column-gap: 13px;
        button.cancel {
          width: 200px;
        }
      }
      &__forward {
        .next {
          width: 200px;
        }
      }
    }
  }
}
