@import '../../assets/styles/index.scss';

.connect-account-steps {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 24px 40px 24px;
  margin-bottom: 8px;
  overflow: auto;
  border-radius: 10px;
  font-size: 16px;

  @media (min-height: $desktop) {
    height: 82vh;
    padding-left: 32px;
    margin-bottom: 0;
  }
  @include customScroll;
  .aws_image {
    width: 90%;
    height: 100%;
    border-radius: 6px;
  }
}

.stepTitle {
  color: $black-800;
  font-weight: 600;
}

.stepDesc {
  color: $black-700;
  font-weight: 400;
  width: 66%;

  &__accountId {
    color: $black-700;
    font-size: 14px;
    font-weight: 600;
  }
}

.bottomCardWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: $tablet) {
    justify-content: flex-end;
  }

  @media (min-width: $desktop) {
    margin-right: 2px;
    margin-bottom: 2px;
    bottom: 0;
  }

  &__bottomCard {
    width: 100%;
    padding: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (min-width: $tablet) {
      flex-direction: row;
      gap: 0;
    }

    background-color: $white;

    &__btnContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &__confirmBtn {
        background-color: $success-800;
      }

      &__confirmBtnDisabled {
        background-color: $success-800;
        opacity: 0.5;
      }
    }
  }
}

.bottomCardWrapper__bottomCard.confirm {
  display: flex;
  justify-content: flex-end;
  gap: 45px;
}
.container-steps-status {
  display: flex;
  gap: 16px;
  align-items: center;
}
.container-status {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 25px;
  padding: 4px;
  border-radius: 4px;
}
.container-status.pending {
  background-color: $warning-100;
  border: 1px solid $warning-700;
}
.container-status.success {
  background-color: $success-100;
  border: 1px solid $success-700;
}
.status-text {
  font-family: Noto Sans;
  font-size: 12.8px;
  font-weight: 500;
  line-height: 17.43px;
  text-align: left;
}

.status-text.pending {
  color: $warning-700;
}
.status-text.success {
  color: $success-800;
}
.bottomCardWrapper__bottomCard__btnContainer .connect_btn {
  width: 148px;
}
.bottomCardWrapper__bottomCard__btnContainer .connect_and_save_btn {
  width: 221px;
}
.bottomCardWrapper__bottomCard__btnContainer .visit_azure_portal_btn {
  width: 157px;
}

.bottomCardWrapper__bottomCard__btnContainer .confirm_btn,
.azure_finsh_btn {
  width: 97px;
}

.connect-azure-heading {
  margin-bottom: 0 !important;
}
@media (max-width: $desktop_sm) {
  .stepDesc {
    width: 95%;
  }
  .connect-account-steps .aws_image {
    height: auto;
  }
}

@media (max-width: $mobile) {
  .bottomCardWrapper__bottomCard {
    flex-direction: column;
    gap: 12px;
  }
  .bottomCardWrapper__bottomCard__btnContainer {
    display: contents;
  }
  .bottomCardWrapper__bottomCard__btnContainer .connect_btn,
  .confirm_btn,
  .connect_and_save_btn,
  .visit_azure_portal_btn,
  .azure_finsh_btn,
  .back_btn {
    width: 100% !important;
  }

  .status-text {
    font-size: 8px;
    line-height: 1;
  }
  .container-status {
    height: auto;
    gap: 2px;
  }
  .container-status .status_icon {
    width: 80%;
  }
  .bottomCardWrapper__bottomCard.confirm {
    gap: 12px;
  }
}
