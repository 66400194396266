@import '../../../../assets/styles/index.scss';

.tm-add-group-selected-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  background: $primary-100;
  column-gap: 12px;
  min-width: 229px;
  width: fit-content;
  height: fit-content;
  align-self: start;
  cursor: pointer;
  &__user {
    @include flexCol;
    row-gap: 8px;
    &__name {
      color: $black-800;
    }
    &__email {
      color: $primary-900;
    }
  }
  &__close-icon {
    height: 24px;
    width: 24px;
  }
}
