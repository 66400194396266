@import '../../../assets/styles/index.scss';
@import '../users/users.scss';

@mixin tableActionIcon {
  padding: 8px;
  border-radius: 4px;
  svg {
    height: 18px;
    width: 18px;
  }
}

.team-management-deactivated-table {
  height: 100%;
  &__search {
    @include searchBar;
    margin-top: 11px;
    &__input-field {
      width: 100%;
    }
    &__buttons {
      justify-content: center;
      column-gap: 16px;
      width: 100%;
      & > button {
        padding: 0 21px;
      }
      .delete-user {
        @include lightRedButton;
        flex-basis: 50%;
      }
      .activate-user {
        @include blueButton;
        flex-basis: 50%;
        flex-grow: 1;
        column-gap: 8px;
      }
    }
  }
  &__table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100%;
    tbody > tr {
      td {
        .checkbox {
          cursor: pointer;
        }
        .item-no {
          display: flex;
        }
        .user-count {
          width: fit-content;
          padding: 2px 10px;
          border-radius: 4px;
          color: $normal-text;
        }
        .group-name {
          display: flex;
          column-gap: 7px;
        }
        .actions {
          @include flexRow;
          column-gap: 12px;
          &__reactivate-user,
          &__delete-user {
            @include tableActionIcon;
          }
          &__reactivate-user {
            background: $primary-600;
          }
          &__delete-user {
            background: $errors-200;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .team-management-deactivated-table {
    &__search {
      margin: 24px 0;
      justify-content: space-between;
      &__buttons {
        width: fit-content;
        .activate-user {
          flex-basis: auto;
          flex-grow: auto;
          width: 179px;
        }
        .delete-user {
          flex-basis: auto;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .team-management-deactivated-table {
    &__search {
      &__input-field {
        width: 400px;
      }
    }
  }
}

.noData {
  width: 100%;
  height: calc(100vh - 178px);

  .wrapper {
    @include flexCol;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;

    .description {
      text-align: center;
      width: 333px;
    }
  }
}

.team-management-table--is-loading {
  .apply-loader {
    @include loaderSkeleton;
  }
}
