@import '../../assets/styles/index.scss';

.user-onboarding-modal-container {
  .user-onboarding {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    height: 100%;
    background: linear-gradient(284deg, #fff 9.38%, $blue-3 89.24%);
    overflow-y: auto;
    @include customScroll;
    padding: 20px 10px;
    &__logo {
      align-self: center;
      svg {
        width: 257px;
        height: 76px;
      }
    }
    .user-onboarding-inner {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      border-radius: 16px;
      padding: 24px;
      align-self: center;
      &__heading {
        color: $black-900;
        text-align: center;
      }
      &__steps {
        display: flex;
        justify-content: space-between;
        @include flexCol;
        @include flexAlignItemsCenter;
        row-gap: 20px;

        margin-bottom: 36px;
        &__step {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 44px;
          width: 44px;
          color: $black-2;
          text-align: center;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          border-radius: 50%;
          border: 1px solid $black-2;
          user-select: none;
          &--is-complete {
            color: #fff;
            border: none;
            background: $blue-3;
            &::after {
              color: $black-1000;
            }
          }
          &:nth-child(1)::after {
            left: auto;
          }
          &:nth-child(2)::after {
            right: 0;
          }
          &::after {
            position: absolute;
            content: attr(data-label);
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            white-space: nowrap;
            top: calc(100% + 8px);
            color: $black-1000;
          }
          &--is-active {
            color: $blue-4;
            border-color: $blue-4;
            &::after {
              color: $primary-800;
            }
          }
        }
        hr {
          width: 150px;
          height: 1px;
          margin-bottom: 10px;
          align-self: flex-end;
          background: $grey-1;
        }
      }
      &__form {
        display: flex;
        flex-direction: column;
        padding: 0;
        &__row {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &__field {
            display: flex;
            flex-direction: column;
            &__label {
              color: $black-900;
              margin-bottom: 8px;
              &::after {
                color: $errors-800 !important;
              }
            }
            &__input {
              font-family: Noto Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 8px;
              &::placeholder {
                font-family: Noto Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              &.input_fields {
                margin-top: 0;
              }
            }
            &__error-message {
              color: $errors-800;
              min-height: 19px;
            }
          }
        }
        &__button {
          width: 300px;
          height: 45px;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 24px;
        }
        &__step-one-continue,
        &__submit {
          color: #fff;
          align-self: center;
          border-radius: 6px;
          background: $blue-3;
        }
        &__step-two-back {
          color: $blue-5;
          border: 1px solid $blue-5;
        }
        &__buttons {
          display: flex;
          justify-content: center;
          gap: 24px;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .user-onboarding-modal-container {
    .user-onboarding {
      padding: 32px 0 0 0;
      height: 100dvh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__logo {
        align-self: flex-start;
        padding: 0 64px;
      }
      .user-onboarding-inner {
        width: 800px;
        &__steps {
          @include flexRow;
          row-gap: 0;
          width: 361px;
          align-self: center;
          margin-bottom: 32px;
          hr {
            width: 167px;
          }
          &__step {
            &:nth-child(1)::after {
              left: initial;
            }
            &:nth-child(2)::after {
              right: initial;
            }
          }
        }
        &__form {
          &__row {
            flex-direction: row;
            &__field {
              flex-basis: 364px;
            }
          }
          &__buttons {
            column-gap: 24px;
            flex-direction: row;
          }
        }
      }
      &__footer {
        .footer {
          padding: 12px 24px;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .user-onboarding-modal-container {
    .user-onboarding {
      padding: 32px 0 0 0;
      &__logo {
        padding: 0 64px;
      }
      &__footer {
        .footer {
          padding: 12px 24px;
        }
      }
    }
  }
}
//Style @  Mobile
@media screen and (max-width: 650px) {
  .user-onboarding-modal-container .user-onboarding .user-onboarding-inner__form__row {
    gap: 0px !important;
  }
  .user-onboarding-modal-container .user-onboarding .user-onboarding-inner__form {
    gap: 0px !important;
  }
  .user-onboarding-modal-container .user-onboarding .user-onboarding-inner__form__button {
    width: 100%;
  }
}

//Style @  Tablet
@media screen and (max-width: 768px) {
  .user-onboarding-modal-container .user-onboarding {
    padding-left: 24px;
    padding-right: 24px;
  }
  .user-onboarding-modal-container .user-onboarding .user-onboarding-inner {
    width: 100%;
  }
  .user-onboarding-modal-container .user-onboarding .user-onboarding-inner__form__row {
    gap: 14px;
  }
}
