@import '../../../assets/styles/index.scss';

@mixin arrayItemCellStyling {
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 0;
  border-radius: 4px;
  width: fit-content;
  &--is-empty {
    color: $black-800;
  }
}

.team-management-table {
  height: 100%;
  &--is-loading {
    border-radius: 10px;
    background: #fff;
    .apply-loader {
      @include loaderSkeleton;
    }
  }
  &__user-summary {
    display: flex;
    flex-direction: column;
    &__buttons-row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 0px 0px 0px 0px;
      row-gap: 10px;
      &__buttons {
        justify-content: flex-end;
        column-gap: 16px;
        width: 100%;
        .add-user {
          @include blueButton;
          flex-basis: 50%;
          height: 40px;
          flex-grow: 1;
          &:disabled {
            opacity: 0.6;
            pointer-events: none;
            cursor: initial;
            &:hover {
              box-shadow: none;
            }
          }
        }
        .archive-user {
          @include lightRedButton;
          flex-basis: 50%;
          height: 40px;
          column-gap: 5px;
          &:disabled {
            opacity: 0.6;
            pointer-events: none;
            cursor: initial;
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
      &__label {
        color: $black-800;
      }
    }
    &__stats {
      .tmg-user-summary-stats {
        .molecule-summary-stats__item__right__value {
          color: $primary-600;
        }
      }
    }
  }
  &__label {
    margin: 16px 0 24px 0;
    color: $black-800;
  }
  &__search {
    @include searchBar;
    margin-top: 11px;
  }
  &__table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100%;
    thead > tr {
      th {
        .checkbox {
          cursor: pointer;
        }
        .users-action-head {
          @include flexJustifyCenter;
        }
      }
    }
    tbody > tr {
      td {
        .checkbox {
          cursor: pointer;
        }
        .item-no {
          display: flex;
        }
        .groups-row-cell {
          @include arrayItemCellStyling;
        }
        .user-access-row-cell {
          @include arrayItemCellStyling;
        }
        .view-more-modal {
          margin-left: 5px;
          color: $primary-600;
        }
        .first-name,
        .last-name {
          position: relative;
          display: flex;
          align-items: center;
          column-gap: 4px;
          color: $primary-600;
          line-height: 22px;
          &__no-signed-in {
            position: absolute;
            left: -20px;
          }
        }
        .user-groups,
        .user-access {
          padding: 5px; // this small padding helps with calculating the modal position of groups and access.
        }
        .action {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          align-self: center;
          svg {
            padding: 5px;
            background: $errors-200;
            border-radius: 4px;
            height: 34px;
            width: 34px;
          }
        }
      }
      &.common-table-container__table__body__row {
        &--is-disabled {
          .first-name,
          .last-name {
            color: $black-800;
          }
        }
      }
    }
  }
  &__view-more-modal {
    padding-right: 10px;
  }
}

@media screen and (min-width: $tablet) {
  .team-management-table {
    &__user-summary {
      &__buttons-row {
        flex-direction: row;
      }
    }
    &__search {
      margin-top: 12px;
      margin-bottom: 24px;
      justify-content: space-between;
      &__buttons {
        width: fit-content;
        .add-user {
          width: 132px;
          flex-basis: auto;
          flex-grow: auto;
        }
        .archive-user {
          width: 160px;
          flex-basis: auto;
        }
      }
    }
  }
  .team-management-table__search {
    width: 625px;
  }
}

.tmg-user-summary-stats {
  display: grid;
  grid-template-columns: minmax(22.5%, 100%) minmax(22.5%, 100%) minmax(23%, 100%) minmax(26%, 100%);
}
@media only screen and (max-width: 1260px) {
  .tmg-user-summary-stats {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}
@media only screen and (max-width: $tablet) {
  .team-management-table__user-summary__buttons-row {
    margin-top: 24px;
    align-items: center;
  }
}
