@mixin button {
  border-radius: 6px;
  height: 40px;
  text-align: center;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    box-shadow: 0 1px 9px 0 rgba(18, 18, 18, 0.1);
  }
}

@mixin whiteButton {
  @include button;
  border: 1px solid $primary-600;
  &:hover {
    box-shadow: 0 1px 9px 0 rgba(114, 112, 112, 0.9);
  }
  background: $white;
  color: $primary-600;
}

@mixin redButton {
  @include button;
  background: $errors-700;
  color: white;
  &:hover {
    box-shadow: 0 1px 9px 0 rgba(114, 112, 112, 0.9);
  }
  &:disabled {
    opacity: 0.6;
  }
}

@mixin lightRedButton {
  @include button;
  border: 1px solid $errors-700;
  background: $errors-100;
  color: $errors-700;
  &:disabled {
    opacity: 0.6;
  }
  &:hover {
    box-shadow: 0 1px 9px 0 rgba(114, 112, 112, 0.9);
  }
}

@mixin blueButton {
  @include button;
  &:hover {
    box-shadow: 0 1px 9px 0 rgba(114, 112, 112, 0.9);
  }
  color: $white;
  background: $primary-700;
}

@mixin disabledButton {
  @include button;
  color: $normal-text;
  border: 1px solid $primary;
  background: $light-grey-1;
  cursor: not-allowed;
}

@mixin tertiaryButton {
  @include button;
  border: none;
  color: $primary-900;
  &:hover {
    background-color: $primary-100;
  }
}

@media screen and (max-width: $mobile) {
  .hide_button_icon,
  .hide_button,
  .hide_button_text {
    display: none;
  }
}
