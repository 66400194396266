@import '../../../assets/styles/index.scss';

.common-accordion {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2px 0 0;
    cursor: pointer;
    &--is-open {
      .common-accordion__head__arrow-icon {
        transform: rotateX(180deg);
      }
    }
    &__title {
      color: $black-900;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &__handler {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      background: $primary-300;
      border-radius: 4px;
    }
    &__arrow-icon {
      height: 16px;
      width: 16px;
      transition: all 0.25s linear;
      cursor: pointer;
    }
  }
  &__content {
    height: 0;
    overflow: hidden;
    transition: all 0.25s linear;
    &--is-open {
      @include customScroll;
    }
  }
  &--is-loading {
    background: none !important;
    pointer-events: none;
    box-shadow: none !important;
    .common-accordion__head {
      background: initial;
    }
    .apply-loader {
      @include loaderSkeleton;
      pointer-events: none;
      display: block;
    }
  }
  &--is-open {
    .common-accordion__head {
      margin-bottom: 16px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .common-accordion {
    &--is-open {
      .common-accordion__head {
        margin-bottom: 32px;
      }
    }
  }
}
