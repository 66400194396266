@import '../../../assets/styles/globals/index.scss';

.MuiModal-root#common-delete-modal {
  display: flex;
}

.common-delete-modal {
  width: 460px;
  padding: 24px 24px 48px 24px;
  background: #fff;
  outline: none;
  margin: 92px auto auto auto;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.deleteModal {
  &__close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__heading {
      color: $black-1000;
    }

    &__child {
      color: $black-800;
    }

    &__description {
      width: 90%;
      font-size: 16px;
      color: $black-800;
    }

    input {
      @include inputField;
    }

    &__buttonsWrapper {
      display: flex;
      gap: 16px;
      width: 100%;
      justify-content: space-between;

      button {
        width: 198px;
      }

      &__delete {
        background: $errors-700;
      }
    }
  }
}
@media only screen and (max-width: $mobile) {
  .common-delete-modal {
    width: 95%;
    height: fit-content;
  }
}
