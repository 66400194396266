@import '../../../assets/styles/index.scss';

@mixin headingTextStyle {
  color: $black-1000;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

@mixin tabItem {
  min-height: 47px;
  padding: 12.5px 16px;
  border-bottom: 1px solid $light-grey-5;
}

@mixin itemHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 16px;
  padding: 0px 0px 12.5px 0px;
  &__title {
    color: $black-1000;
  }
  &__edit {
    @include whiteButton;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    padding: 8px 21px 8px 21px;
    color: $primary-600;
    &:disabled {
      opacity: 50%;
    }
  }
}

@mixin dropdownStyling {
  position: relative;
  &__current,
  &__select {
    @include headingTextStyle;
    display: flex;
    justify-content: space-between;
    column-gap: 6.5px;
    align-items: center;
    cursor: pointer;
    padding: 12px 16px;
    font-weight: 400;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid $primary;
    background: #fff;
    color: $black-900;
    width: 100%;
  }
  &__dropdown {
    position: absolute;
    display: none;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 8px 60px 0px rgba(18, 18, 18, 0.15);
    border: 1px solid $primary-200;
    width: 100%;
    max-height: 262px;
    z-index: 99;
    overflow-y: auto;
    @include customScroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    .module-item {
      cursor: pointer;
      height: 34px;
      color: $black-1000;
      white-space: nowrap;
      padding: 8px 16px;
      &:hover {
        background: $primary-200;

        svg path {
          stroke: $blue-2;
        }
      }
      &--is-disabled {
        color: $light-grey-1;
        svg path {
          stroke: $light-grey-1;
        }
        &:hover {
          color: $light-grey-1;
          svg path {
            stroke: $light-grey-1;
          }
        }
      }
    }
    &--is-open {
      display: flex;
      flex-direction: column;
      width: fit-content;
    }
  }
  @media screen and (min-width: $desktop) {
    &__current,
    &__select {
      width: 400px;
    }
    &__dropdown {
      &--is-open {
        width: 400px;
      }
    }
  }
}

.access-control {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    .reset-password {
      @include blueButton;
      display: flex;
      column-gap: 14px;
      align-items: center;
      padding: 0 21px;
      height: 40px;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      column-gap: 14px;
      padding: 0 21px;
      height: 40px;
      white-space: nowrap;
      &:disabled {
        opacity: 50%;
      }
    }
  }
  &__tab-item {
    padding: 16px;
    &__items {
      &__heading {
        @include itemHeading;
        padding-bottom: 0;
      }
      &__item {
        @include tabItem;
        display: flex;
        column-gap: 7px;
        color: $black-1000;
        .group-item {
          color: $black-1000;
          overflow-wrap: anywhere;
        }
        &:last-child {
          border-bottom: none;
          flex-direction: column;
        }
      }
    }
    .no-groups-found {
      color: $black-1000;
    }
  }
  &__groups {
    flex: 1;
    &__heading {
      @include itemHeading;
      flex-wrap: wrap;
      gap: 16px;
      &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .add-user-to-group {
          @include blueButton;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 21px;
          gap: 8px;
        }
        button {
          &:disabled {
            opacity: 50%;
          }
        }
      }
    }

    &__items {
      overflow-y: auto;
      overflow-x: hidden;
      @include customScroll;
    }
  }
  &__permissions {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    &__head {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 27px;
      &__title {
        @include headingTextStyle;
        font-weight: 400;
      }
      &__select-module {
        @include dropdownStyling;
      }
    }
    &__items {
      &__permissions-container {
        @include customScroll;
        overflow-y: auto;
      }
    }
  }
  &__item {
    border-radius: 4px;
    background: $light-blue-1;
    padding: 2px 10px;
    width: fit-content;
  }
  &__view-more-list {
    position: absolute;
    bottom: 0;
    bottom: calc(100% + 15px);
  }
  &__view-more-button {
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
  .owner-permissions-view {
    @include headingTextStyle;
    font-weight: normal;
    &__link {
      cursor: pointer;
      color: $blue-2;
    }
  }
}

.reset-pass-container {
  display: flex;
  justify-content: flex-end;
}
// //This Media Query for specific requirement
@media (max-width: 1000px) and (min-width: 769px) {
  .access-control__tabs {
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
  }
}

@media screen and (min-width: $desktop) {
  .access-control {
    &__view-more-list {
      bottom: 0;
      left: calc(100% + 15px);
    }
  }
}

@media screen and (max-width: $mobile) {
  .access-control {
    margin-bottom: 32px;
  }
  .my-profile__main {
    margin-bottom: 16px;
  }
  .my-profile__password {
    margin-bottom: 16px;
  }
  .my-profile__main__user-data__personal-information__fields--is-edit {
    gap: 16px;
  }
  .my-profile__main__user-data__personal-information__fields {
    gap: 16px !important;
  }
  .access-control__groups__heading {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
  .access-control__groups__heading__title {
    width: 100%;
  }
  .access-control__groups__heading__buttons {
    gap: 16px;
  }
  .access-control__groups__heading__edit {
    padding: 8px 14px 8px 14px;
  }
  .access-control__groups__heading__buttons .add-user-to-group {
    padding: 8px 14px 8px 14px;
  }
  .access-control__tab-item__items__heading__edit {
    padding: 8px 14px 8px 14px;
  }
  .access-control__permissions__head__select-module__current,
  .access-control__permissions__head__select-module__select {
    padding: 8px 14px 8px 14px;
  }
  .access-control__tabs {
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 8px;
  }
  .reset-pass-container {
    display: flex;
    justify-content: center;
  }
  .reset-pass-container button {
    width: 95%;
    justify-content: center;
  }
}

@media screen and (max-width: $tablet) {
  .access-control__permissions__items__permissions-container {
    height: auto !important;
  }
  .access-control__tab-item__items__item {
    padding-left: 0;
  }
  .access-control__permissions__head {
    justify-content: space-between;
  }
  .access-control__permissions {
    gap: 16px;
  }
}
.access-control__tab-item__items__item {
  flex-direction: column;
}

.access-control__permissions__items .description {
  padding: 4px 0 0 0;
}
