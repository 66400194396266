@import '../../assets/styles/index.scss';

@mixin usersHeading {
  color: $normal-text;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 22px;
}

.cloud-accounts-connect-email {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 150px);
  padding: 24px;
  gap: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__heading {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 3px;

      &__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 6px;

        img {
          width: 32px;
          object-fit: contain;
        }

        span {
          color: $black-900;
        }
      }

      &__sub-title {
        color: $black-700;
      }
    }

    &__close {
      cursor: pointer;
    }
  }

  &__users {
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;

    &__select {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 350px;

      &__heading {
        color: $black-800;

        &__searchDiv {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 8px;
          border: 1px solid $black-600;
          background: $white;
          padding: 12px 16px;
          width: 100%;
          gap: 8px;

          &__search-input {
            outline: none;
            border: none;
            &::placeholder {
              font-family: Noto Sans;
              font-size: 14px;
              color: $light-grey-2;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      &__list {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        height: 400px;
        overflow-y: auto;
        padding-right: 16px;
        @include customScroll;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &__user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 12px;

          &__userView {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 16px 12px;

            &__userDetails {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;

              &__userDetailsText {
                color: $black-1000;
                // width: calc(50% - 1px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              &__line {
                width: 1px;
                height: 19px;
                background-color: $black-600;
              }
            }

            &__email {
              color: $black-700;
              text-align: left;
            }

            &__checkboxWrapper {
              padding: 16px 12px;

              &__checkbox {
                position: relative;
                height: 20px;
                width: 20px;
                appearance: none;
                /* Remove default appearance */
                -webkit-appearance: none;
                /* For Safari */
                -moz-appearance: none;
                /* For Firefox */
                border: 2px solid $black-600;
                border-radius: 4px;
                /* Remove outline */
                cursor: pointer;

                /* Add pointer cursor */
                &:checked {
                  border: 2px solid $primary-700;
                }

                &::before {
                  content: url('../../assets/images/svg/blueCheck.svg');
                  /* Unicode character for checkmark */
                  font-size: 14px;
                  /* Set font size */
                  color: $primary-700;
                  position: absolute;
                  top: 65%;
                  left: 55%;
                  transform: translate(-50%, -50%);
                  visibility: hidden;
                }

                &:checked::before {
                  visibility: visible;
                }
              }
            }
          }

          & > * {
            cursor: pointer;
          }

          label {
            color: $normal-text;
            font-family: Noto Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        &__selectedUser {
          background-color: $primary-200;
          border-radius: 8px;
        }

        &__tooltip {
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          p {
            text-align: left;
            word-break: keep-all;
          }
        }
      }
    }

    &__ruler {
      height: 100%;

      div {
        height: 100vh;
      }
    }

    &__selected-users {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 350px;

      &__headerText {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        color: $black-900;
      }

      &__heading {
        color: $black-800;
      }

      &__list {
        @include flexRow;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 24px;
        height: 456px;
        overflow-y: auto;
        @include customScroll;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &__user {
          border-radius: 8px;
          background: $primary-100;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 272px;
          padding: 8px 12px;
          column-gap: 10px;
          cursor: pointer;

          &__userView {
            display: flex;
            flex-direction: column;
            gap: 4px;

            &__userDetails {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;

              &__userDetailsText {
                color: $black-1000;
                // width: calc(50% - 1px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              &__name {
                color: $black-800;
              }
              &__line {
                width: 1px;
                height: 19px;
                background-color: $black-600;
              }
            }

            &__email {
              color: $primary-600;
              text-align: left;
            }
          }

          p {
            font-family: Noto Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        &--is-empty {
          color: $normal-text;
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;

    &__isDisabled {
      opacity: 0.5;
    }
  }
}
.fullName,
.user_email {
  word-break: break-all;
}
@media screen and (min-width: $tablet) {
  .cloud-accounts-connect-email {
    &__users {
      justify-content: center;
      column-gap: 24px;
      flex-direction: row;
    }
  }
}
@media screen and (max-width: $desktop_sm) {
  .cloud-accounts-connect-email__users__selected-users__headerText {
    flex-direction: column;
    align-items: self-start;
    gap: 2px;
  }
  .cloud-accounts-connect-email__users {
    column-gap: 16px;
  }
  .cloud-accounts-connect-email__users__selected-users__list {
    gap: 15px;
    padding-right: 16px;
  }
}

@media screen and (max-width: $tablet) {
  .cloud-accounts-connect-email__users__select,
  .cloud-accounts-connect-email__users__selected-users {
    width: 100%;
  }
  .cloud-accounts-connect-email__users__ruler div {
    height: 100%;
  }
}
@media screen and (max-width: $mobile) {
  .cloud-accounts-connect-email__users__selected-users__list__user {
    width: 100%;
  }
  .cloud-accounts-connect-email__users__select {
    height: 320px;
  }
  .fullName,
  .user_email {
    word-break: auto-phrase;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.cloud-accounts-connect-email__users__select__heading__searchDiv__search-input {
  width: 100% !important;
}
