@import '../../../assets/styles/index.scss';

.notifications {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 210px) !important;
  padding-top: 0 !important;
  padding-left: 0 !important;

  &__wrapperContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 24px;

    &__header {
      font-family: Noto Sans;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      &__title {
        color: $primary-600;
      }

      &__description {
        color: $black-800;
      }
      &__ruler {
        border: 0px;
        height: 0.5px;
        background: $primary-200;
      }
    }

    &__container {
      &__subtitle {
        color: $black-900;
      }
      &__box {
        padding-top: 32px;
        font-family: Noto Sans;

        &__wrapper {
          display: grid;
          grid-template-columns: 48% 20% 22% 10%;
          align-items: center;

          div > h4 {
            color: $black-1000;
          }

          &__dropdown {
            button {
              @include showRowsPerPageEntries;
              cursor: pointer;
              display: flex;
              justify-content: center;
              column-gap: 14px;
              align-items: center;
              height: 34px;
              padding: 8px 16px;
              span {
                font-family: Noto Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
          &__disabled {
            button {
              cursor: not-allowed;
              box-shadow: none;
              border: 1px solid $primary-400;
              color: $primary-400;
              img {
                transform: rotate(0deg);
              }
            }
          }

          &__description {
            color: $black-700;
          }

          &__time {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;

            &__para {
              color: $black-800;

              .notification-time-ruler {
                width: 20px;
              }
            }

            &__link {
              text-decoration-line: underline;
              color: $blue-2;
              cursor: pointer;
            }

            img {
              width: 16px;
              height: 16px;
              cursor: pointer;
              &.change-is-disabled {
                filter: contrast(0.1);
              }
            }
          }

          &__switch {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }
  }
  &__ruler {
    height: 1px;
    background: $primary-200;
    border: none;
  }
}

.notifications_loading {
  .apply-loader {
    @include loaderSkeleton;
    display: flex;
    padding: 0 5px;
    width: max-content;
  }
}
.notifications_title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: 16px;
}

@media only screen and (max-width: $tablet) {
  .notifications__wrapperContainer__container__box__wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  .notifications_title {
    order: 1;
    padding-right: 0px;
    width: calc(100% + 70px);
  }
  .notifications__wrapperContainer__container__box__wrapper__switch {
    order: 2;
  }
  .notifications__wrapperContainer__container__box__wrapper__dropdown.notifications__wrapperContainer__container__box__wrapper__disabled {
    order: 3;
  }
  .notifications__wrapperContainer__container__box__wrapper__time {
    order: 4;
    justify-content: flex-end;
  }
}
