/* NeedHelpModal*/

/* Overlay */
.need-help-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: none;
}

.need-help-overlay.open {
  display: block;
}

.header_container {
  display: grid;
  gap: 16px;
}

.need_help_modal {
  position: fixed;
  z-index: 2;
  top: 0;
  right: -200%; /* Modal is hidden by default */
  width: 500px;
  height: auto;
  border-radius: 16px 0px 0px 16px;
  background-color: white;
  transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.need_help_modal.open {
  right: 0; /* Slide in the modal */
}

.need_help_modal_modal_content {
  padding: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal_heading {
  color: #121212;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.24px;
  margin-right: auto;
}
.modal_sub_heading {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}
.needHelpScroll {
  height: calc(100vh - 0vh) !important;
  padding: 0 !important;
}

/*Style @ Mobile*/

@media only screen and (max-width: 600px) {
  .need_help_modal {
    width: 100%;
  }
  .need_help_modal {
    border-radius: 0px;
  }
}
