@import '../../../../assets/styles/index.scss';

.cloud-accounts-email-list {
  margin-top: 16px;
  @include flexCol;

  &__buttons-row {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 12px 0;
  }
  &__search {
    @include searchBar;
    @include flexJustifyFlexStart;
    @include flexAlignItemsCenter;
    column-gap: 16px;
    margin-bottom: 24px;
    width: 545px;
    &__input-field {
      column-gap: 8px;
      width: 400px;
      form {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
  &__table {
    @include flexCol;
    justify-content: space-between;
  }
  .MuiTableContainer-root.common-table-container {
    .MuiTable-root {
      .MuiTableHead-root {
        .email-list-added-on-head {
          @include flexRow;
          @include flexJustifyCenter;
        }
        .email-list-action-head {
          margin: 0 auto;
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            color: $black-800;
            font-family: Noto Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            input {
              cursor: pointer;
            }
            .added-on {
              width: fit-content;
              line-height: 18px;
              text-align: left;
            }
            .remove-email {
              height: 34px;
              width: 34px;
              background: $errors-100;
              border-radius: 4px;
              color: $errors-100;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                height: 18px;
                width: 18px;
              }
            }
            &.email-list-action-td {
              @include flexRow;
              @include flexAlignItemsCenter;
              justify-content: flex-start;
              // @ TODO We need to make seperate align props in the custom table
            }
          }
        }
      }
    }
  }
}
