@import '../../../assets/styles/index.scss';

#teamMgmtAddUserContainer {
  overflow-y: auto;
  @include customScroll;
}

@mixin buttonsGroupFooter {
  @extend .d-flex;
  @extend .justify-content-center;
  gap: 24px;
}

@mixin cardForm {
  @extend .d-flex;
  @extend .d-flex-col;
  width: 648px;
  height: 376px !important;
  margin: auto;
  margin-top: 3%;
  padding: 24px;
  cursor: auto;
  gap: 14px;
}

@mixin cardFormHeader {
  @extend .d-flex;
  @extend .d-flex-col;
  gap: 8px;
}

.addUser {
  padding-top: 16px;
  margin-top: 16px;
  min-height: calc(100vh - 175px) !important;
  cursor: auto;
  padding-bottom: 5rem;
}

.personalInfoInput {
  @include cardForm;

  &__header {
    @include cardFormHeader;
    margin-bottom: 16px;
  }

  &__buttons {
    @include buttonsGroupFooter;
  }
}

.emailInput {
  @include cardForm;
  height: auto !important;
  &__back {
    @extend .d-flex;
    @extend .cursor-pointer;
    width: max-content;
  }

  &__header {
    @include cardFormHeader;
    margin-bottom: 16px;
  }

  &__buttons {
    @include buttonsGroupFooter;
  }
}

@media only screen and (max-width: $tablet) {
  .personalInfoInput,
  .emailInput {
    width: 100%;
    box-shadow: none;
    padding: 30px;
  }
}
