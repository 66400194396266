@mixin errorMessage {
  color: $errors-800;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  visibility: hidden;
  &--is-visible {
    visibility: visible;
  }
}
