@import '../../../assets/styles/index.scss';
.container-logo-with-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 24px;
}
.connect-heading {
  color: $black-2;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.48px;
  text-align: left;
}
