@import '../../../assets/styles/index.scss';

.add-user-review-container {
  margin: 18px 11px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include customScroll;
  &__go-back {
    display: flex;
    column-gap: 8px;
    cursor: pointer;
  }
  &__heading {
    color: $black-1000;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    & > * {
      text-align: center;
    }
  }
  &__personal-info {
    color: $black-800;
    &__content {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 121px;
      row-gap: 15px;
      padding: 0 16px 16px 16px;
      &__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 18px;
        padding: 12.5px 11px;
        .label {
          flex-basis: 100px;
          flex-shrink: 0;
        }
        .value {
          word-break: break-word;
        }
      }
    }
  }
  &__permissions {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    &__heading {
      color: $black-900;
    }
  }
  &__navigation {
    display: flex;
    justify-content: center;
    gap: 24px;
    background: #fff;
    &__backward {
      display: flex;
      column-gap: 18px;
      .cancel {
        @include whiteButton;
        width: 98px;
      }
      .goback {
        @include whiteButton;
        width: 75px;
        border: none;
        background: none;
        &:hover {
          box-shadow: none;
          background: $light-blue-3;
        }
      }
    }
    &__forward {
      .next {
        @include blueButton;
        width: 98px;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .add-user-review-container {
    &__personal-info {
      &__content {
        &__item {
          padding: 12.5px 11px 12.5px 20px;
        }
      }
    }
    &__navigation {
      &__backward {
        column-gap: 13px;
        .cancel {
          width: 200px;
        }
        .goback {
          width: 200px;
        }
      }
      &__forward {
        .next {
          width: 200px;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .add-user-review-container {
    &__personal-info {
      &__content {
        grid-template-columns: 400px 400px;
      }
    }
  }
}

@media only screen and (max-width:$mobile){
  .add-user-review-container__personal-info__content{
   display: block;
  }
}