@import '../../../../assets/styles/index.scss';

.tm-edit-group {
  margin-top: 16px;
  @include customScroll;
  .tm-edit-group-group-name-form {
    display: flex;
    justify-content: center;
    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 24px;
      margin-top: 56px;
      padding: 24px;
      height: 350px;
      &__heading {
        color: $black-900;
      }
      &__buttons {
        display: flex;
        justify-content: center;
        column-gap: 24px;
        button.cancel_button.secondary,
        button.next_button.primary {
          width: 200px;
        }

        @include minTablet {
          padding-left: 2.5rem;
          padding-right: 2.5rem;
        }
      }
    }
  }
}
.edit_user_buttons .cancel,
.create_group_button {
  width: 200px;
}
.edit-group-add-user {
  display: flex;
  height: -webkit-fill-available;
  flex-direction: column;
  padding: 0 16px;
  margin: 32px 0;
  justify-content: flex-start;
  column-gap: 44px;
  row-gap: 32px;
  color: $normal_text;

  .search-selected {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .edit_users {
    @include flexCol;
    row-gap: 16px;
    padding: 16px;

    &__search {
      @include flexRow;
      @include flexAlignItemsCenter;
      column-gap: 16px;
      button {
        width: 30%;
        padding: 0 8px;
      }
    }

    .search_input {
      border-radius: 8px;
      border: 1px solid #c4c4c4;
      height: 40px;
      margin-top: 2px;
      padding-inline: 14px;
      padding-inline-end: 14px;
      box-sizing: border-box;
    }

    .search_input::placeholder {
      font-family: Noto Sans;
      font-size: 14px;
      color: #727272;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .search_input:hover {
      box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
    }

    .search_input:focus {
      outline: none; /* Remove default focus outline */
      border: 1px solid#33475B;
      box-shadow: none;
      outline-offset: 2px;
      padding-inline: 14px;
      padding-inline-end: 14px;
    }

    .view-more {
      cursor: pointer;
      text-align: center;
      color: $blue-2;
      font-family: Noto Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .list-container {
    overflow-y: auto;
    height: 400px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    @include customScroll;
    label {
      color: $normal_text;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .user-tooltip {
      padding: 14px 21px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      p {
        text-align: left;
      }
    }
  }

  .selected-users-container {
    @include flexCol;
    row-gap: 12px;
    padding: 16px;
    &__heading {
      @include flexRow;
      @include flexAlignItemsCenter;
      column-gap: 8px;
    }
  }

  .selected-users-container_list {
    overflow-y: auto;
    height: 400px;
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    font-size: 14px;
    @include customScroll;
  }

  .edit_user_buttons {
    @include flexJustifyCenter;
    column-gap: 24px;
    padding-bottom: 100px;
    .left_buttons {
      display: flex;
      column-gap: 20px;
      .cancel {
        @include whiteButton;

        width: 200px;
      }
      .back {
        @include tertiaryButton;
        width: 200px;
      }
    }
    .create_group_button {
      @include blueButton;
      width: 200px;
    }
  }
}

.edit_users__search {
  width: 100%;
}
.edit-group-add-user .edit_users .search_input {
  width: 70%;
}

@media screen and (min-width: $desktop) {
  .tm-edit-group {
    .tm-edit-group-group-name-form {
      &__inner {
        width: 650px;
      }
    }
  }

  .edit-group-add-user {
    .selected-users-container {
      width: 32%;
    }
    .search-selected {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
    }
    .edit_users {
      width: 32%;
      display: flex;
      flex-direction: column;
    }
  }
}
.editGroupContainer {
  min-height: calc(100vh - 175px) !important;
  padding-top: 16px;
  margin-top: 16px;
  padding-bottom: 5rem;
}
.tm-edit-group .tm-edit-group-group-name-form__inner {
  height: 360px !important;
  margin: auto;
  margin-top: 3%;
  padding: 24px;
  cursor: auto;
  gap: 24px;
}

@media only screen and (max-width: $tablet) {
  .tm-edit-group .tm-edit-group-group-name-form__inner {
    box-shadow: none;
    padding: 30px;
    width: 100%;
  }
}
