@import '../../../assets/styles/index.scss';

.tabs-container {
  width: fit-content;
  // border-bottom: 1px solid $grey-2;
  overflow: auto;

  &--is-loading {
    .apply-loader {
      padding: 0;
      margin: 10px 16px;
      @include loaderSkeleton;
    }
  }
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tab_items {
  color: $black-700;
  cursor: pointer;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 180px;
  padding: 8px 16px;
  text-align: center;
  white-space: nowrap;
  text-transform: capitalize;
  border-bottom: 1px solid $grey-2;

  &.tab_items_active {
    color: $primary-600;
  }

  &.tab_items_disabled {
    color: $light-grey-1;
  }

  &.apply-loader {
    @include loaderSkeleton;
  }
}

@media only screen and (max-width: $mobile) {
  .tabs-container {
    width: auto;
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tab_items {
    min-width: auto;
  }
}

@media only screen and (max-width: $tablet) {
  .tabs-container {
    width: 100%;
  }
}
