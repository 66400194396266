input[type='checkbox']:not(.MuiSwitch-input) {
  // Should not apply to Material UI switch in settings
  position: relative;
  height: 20px;
  width: 20px;
  appearance: none;
  /* Remove default appearance */
  -webkit-appearance: none;
  /* For Safari */
  -moz-appearance: none;
  /* For Firefox */
  border: 2px solid $black-600;
  border-radius: 4px;
  /* Remove outline */
  cursor: pointer;

  /* Add pointer cursor */
  &:checked {
    border: 2px solid $primary-600;
  }

  &::before {
    content: url('../../images/svg/inputCheckbox.svg');
    /* Unicode character for checkmark */
    font-size: 14px;
    /* Set font size */
    color: $primary-600;
    visibility: hidden;
  }

  &:checked::before {
    visibility: visible;
  }
  &:disabled {
    border: 2px solid $black-400;
    &:checked {
      border: 2px solid $black-400;
      background: none;
      &::before {
        content: url('../../images/svg/inputCheckboxGray.svg');
      }
    }
  }
  &.select-all-checkbox {
    &:checked {
      border: 2px solid $black-600;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 13px;
      height: 3px;
      border-radius: 8px;
      background: $black-600;
      font-size: 14px;
      visibility: hidden;
    }
    &:checked::before {
      visibility: visible;
    }
  }
}
