@import '../../../assets/styles/index.scss';

.custom-step-form {
  &__stepper {
    &__step {
      cursor: default;
      &__label {
        &__text {
          color: $black-1000;
          &--is-active {
            color: $primary-600;
          }
          &--is-complete {
            cursor: pointer;
          }
        }
      }
    }
  }
}
