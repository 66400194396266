@import '../../../assets/styles/index.scss';
@import '../users/users.scss';

.team-management-groups-table {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
  &__groups-summary {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 0px;

    &__buttons-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: -16px;
      &__label {
        color: $black-800;
      }
      &__buttons {
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        button {
          display: flex;
          align-items: center;
          column-gap: 8px;
          padding: 0 21px;
        }
        .add-group {
          @include blueButton;
          flex-basis: 50%;
          flex-grow: 1;
          &:disabled {
            opacity: 50%;
          }
        }
        .delete-group {
          @include lightRedButton;
          flex-basis: 50%;
          color: $errors-800;
          &:disabled {
            opacity: 50%;
          }
        }
      }
    }
  }
  &__explorer {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    &__search {
      @include searchBar;
      margin: 0 !important;
    }
    &__table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 100%;
      thead > tr {
        th {
          .groups-action-row-head {
            @include flexJustifyCenter;
          }
          .text-center {
            text-align: center;
          }
        }
      }
      tbody > tr {
        td {
          .checkbox {
            cursor: pointer;
          }
          .item-no {
            display: flex;
          }
          .user-count {
            width: fit-content;
            padding: 2px 0;
            border-radius: 4px;
            color: $normal-text;
            background: none;
          }
          .text-left {
            text-align: left;
          }
          .group-name {
            display: flex;
            column-gap: 7px;
            color: $primary-600;
          }
          .action.delete-group {
            display: flex;
            justify-content: flex-start;
            svg {
              padding: 5px;
              background: #fee2e2;
              border-radius: 4px;
              height: 34px;
              width: 34px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .team-management-table {
    &__explorer {
      &__search {
        margin: 0;
        justify-content: space-between;
        &__buttons {
          width: fit-content;
          .add-group {
            width: 112px;
            flex-basis: auto;
            flex-grow: auto;
          }
          .delete-group {
            width: 132px;
            flex-basis: auto;
          }
        }
      }
    }
  }

  .team-management-groups-table {
    &__explorer {
      &__search {
        width: 625px;
      }
    }
  }
}
.tmg-teams-summary-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  .molecule-summary-stats__item__right__value {
    color: $primary-600;
  }
}
@media only screen and (max-width: $tablet) {
  .team-management-groups-table__groups-summary__buttons-row {
    margin-top: 24px;
    align-items: center;
  }
}
