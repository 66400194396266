@import '../../../assets/styles/index.scss';

.connectStep {
  position: relative;
  display: flex;
  font-size: 14px;
  font-family: Noto Sans;
  flex-direction: column;
  // border-left: 1px solid $black-300;
  min-height: 72px;
  gap: 16px;

  .heading {
    color: $black-800;
  }

  .description {
    color: $black-700;
    border-left: 1px solid $black-300;
    margin-left: 1.25rem;
    padding-left: 2rem;
  }
}
