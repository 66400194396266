@import '../../../assets/styles/index.scss';

.groupsNoData {
  width: 100%;
  height: calc(100vh - 250px);
  color: $normal-text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: sticky;
  top: 50%;
  left: 45%;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: inherit;
    gap: 20px;
    position: absolute;

    .description {
      text-align: center;
      max-width: 376px;
      span {
        font-weight: 700;
      }
    }

    button {
      @include blueButton;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

//Style @Mobile
@media only screen and (max-width: 600px) {
  .wrapper {
    width: 250px !important;
  }
}
