@import '../../../../assets/styles/index.scss';

@mixin textStyle {
  color: $normal-text;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
}

@mixin labelStyle {
  font-weight: 700;
}

.team-management {
  &__group-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    margin: 16px 0;
    &__item {
      flex: 1;
      padding: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      color: $black-800;
      width: 100%;
      .value {
        width: 100%;
        color: $black-900;
        overflow-wrap: anywhere;
      }
    }
  }
}

.view-group-users {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  row-gap: 24px;
  &__buttons-row {
    display: flex;
    flex-direction: column;
    &__search {
      @include searchBar;
      @include flexAlignItemsCenter;
      margin-bottom: 0;
      &__input-field {
        column-gap: 8px;
        width: 400px;
        form {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }
    &__buttons {
      display: flex;
      gap: 16px;
      width: 100%;
      margin-bottom: 11px;
      &__button-row {
        display: flex;
        column-gap: 13px;
        white-space: nowrap;
        .add-in-group {
          @include blueButton;
          display: flex;
          column-gap: 5px;
          flex-basis: 100%;
          padding: 0 21px;
          justify-content: center;
          align-items: center;
          &:disabled {
            opacity: 50%;
          }
        }
        .edit-group {
          @include whiteButton;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          border-radius: 6px;
          border: 1px solid $primary-600;
          background: #fff;
          padding: 0 21px;
          height: 40px;
          color: $primary-600;
          &:disabled {
            opacity: 50%;
          }
        }
      }
      .delete-group {
        @include lightRedButton;
        display: flex;
        column-gap: 5px;
        justify-content: center;
        align-items: center;
        color: $errors-800;
        padding: 0 21px;
      }
    }
  }
  &__table {
    flex-basis: unset;
    .view-group-users-action-head {
      @include flexJustifyCenter;
    }
    .first-name,
    .last-name {
      color: $black-800 !important;
    }
  }
}

@media screen and (min-width: $tablet) {
  .view-group-users {
    &__buttons-row {
      &__fields {
        row-gap: 5px;
        grid-template-areas:
          'group-name created-on'
          'created-by created-by';
        margin-bottom: 4px;
        .vertical-bar {
          display: block;
          width: 2px;
          border-left: 1px solid $light-grey-1;
          height: 19px;
          margin: 0 19px;
        }
      }
      &__buttons {
        flex-direction: row-reverse;
        column-gap: 13px;
        &__button-row {
          justify-content: flex-end;
          .add-in-group {
            width: 100%;
            padding: 0 21px;
            flex-basis: auto;
          }
        }
        .delete-group {
          width: auto;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .view-group-users {
    &__buttons-row {
      flex-direction: row;
      justify-content: space-between;
      &__search {
        margin-bottom: 0;
      }
      &__fields {
        row-gap: 16px;
        grid-template-areas:
          'group-name group-name'
          'created-on created-by';
        margin-bottom: 0px;
        .group-name {
          .vertical-bar {
            display: none;
          }
        }
      }
      &__buttons {
        width: auto;
        margin-bottom: 0;
        .delete-group {
          align-self: flex-end;
        }
        &__button-row {
          align-items: self-end;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .view-group-users__buttons-row__buttons .delete-group {
    padding: 0 12px;
  }
  .view-group-users__buttons-row__buttons__button-row .edit-group {
    padding: 0 12px;
  }
}
