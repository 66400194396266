.guest-user {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  position: relative;
}

.guest-user .guest-user__container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.guest-user .guest-user__container__logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(138deg, #274765 0%, #438496 100%);
  padding-bottom: 20px;
}

.guest-user .guest-user__container__logo-container .company-logo {
  width: 179px;
  margin: 26.37px auto 22px auto;
}

.guest-user .guest-user__container__logo-container .integration-logo {
  display: none;
  width: 304px;
  height: auto;
  margin: 55px auto auto auto;
}

.guest-user .guest-user__container__logo-container .sub-heading {
  width: 80%;
  margin-bottom: 28px;
  text-align: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
}

.guest-user .guest-user__container__form-container {
  width: 100%;
  flex: 1;
}
@media only screen and (max-width: 767px) {
  .guest-user .guest-user__container__form-container {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 10.5rem; /* Adjust this value for the desired overlap */
    border-radius: 1.25rem;
  }
}

/* Tablet styles */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .guest-user .guest-user__container__logo-container {
    height: 285px;
    justify-content: flex-start;
    padding-bottom: 63px;
  }
  .guest-user .guest-user__container__logo-container .company-logo {
    width: 257px;
    margin: 40px auto 22px auto;
  }
}

/* Desktop styles */
@media only screen and (min-width: 1024px) {
  .guest-user .guest-user__container {
    flex-direction: row;
  }
  .guest-user .guest-user__container__logo-container {
    align-items: center;
    padding: 0;
    width: 50%;
  }
  .guest-user .guest-user__container__form-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .guest-user .guest-user__container__logo-container .integration-logo {
    display: flex;
  }
  .guest-user .guest-user__container__logo-container .company-logo {
    width: 257px;
    margin: 44px auto 22px auto;
  }
}
