@mixin inputField {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  height: 43px;
  padding: 12px 16px;
  font-family: Noto Sans;
  color: $black-900;
  border: 1px solid $black-600;
  font-size: 14px;
  // &:hover {
  // border: 1px solid $primary-600; enabling this now will create visually inconsistency because of blue color.
  // }
  &:focus {
    outline: none; /* Remove default focus outline */
    border: 1px solid $black-1000;
  }
  &:disabled {
    border: 1px solid $black-400;
    color: $black-400;
    cursor: not-allowed;
    &::placeholder {
      color: $black-400;
    }
  }
  &::placeholder {
    font-family: Noto Sans;
    font-size: 14px;
    color: $black-600;
    font-weight: 400;
    line-height: 19.07px;
  }
}
