@import '../../../assets/styles/index.scss';

.no-data-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: $black-800;
  margin-top: 60px;
  height: inherit;
  text-align: center;
  &-description-loader {
    @include loaderSkeleton;
  }
}
.no-data-found-container img {
  width: 103px !important;
  height: auto !important;
}
.no-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}
