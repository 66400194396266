@mixin customScroll {
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: $primary-200;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary-700;
    border-radius: 5px;
  }
}
