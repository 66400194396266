@import '../../assets/styles/index.scss';

.insights {
  width: 100%;
  height: calc(100vh - 150px);
  border-radius: 10px;
  background: #fff;
  padding: 2px 16px 17px 16px;
  &__content {
    height: 100%;
  }
}

@media screen and (min-width: $tablet) {
  .insights {
    height: calc(100vh - 125px);
  }
}
