@import '../../../assets/styles/index.scss';

.errorVisible {
  visibility: visible;
}

.errorInvisible {
  visibility: hidden;
  color: transparent;
}

.common-text-area-input {
  &__label {
    color: $black-900;
  }
  &__input-field {
    position: relative;
    &__input {
      &--is-currency {
        padding-left: 52px;
        &:focus {
          padding-left: 52px;
        }
      }
    }
  }
  &__error {
    color: $errors-800;
    padding-top: 5px;
    visibility: hidden;
    &--is-visible {
      visibility: visible;
    }
  }
}
