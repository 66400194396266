@import '../../../../assets/styles/index.scss';

.tm-add-group-add-users-to-group {
  display: flex;
  @include flexAlignItemsCenter;
  justify-content: space-between;
  padding: 16px 12px;
  border-radius: 8px;
  margin-right: 12px;
  column-gap: 0.5rem;
  &__user {
    @include flexCol;
    row-gap: 8px;
    .name {
      color: $black-1000;
    }
    .email {
      color: $black-700;
    }
  }
  &--is-selected,
  &:hover {
    background: $primary-200;
  }
}
