.typography {
  &__display-1 {
    font-family: Raleway;
    font-size: 95px;
    line-height: 112px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__display-2 {
    font-family: Raleway;
    font-size: 76px;
    line-height: 90px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__display-3 {
    font-family: Raleway;
    font-size: 61px;
    line-height: 72px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__heading-1 {
    font-family: Raleway;
    font-size: 49px;
    line-height: 57px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__regular {
    font-family: Raleway;
    font-size: 39px;
    line-height: 48px;
    letter-spacing: -0.005em;

    &__heading-2 {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__heading-3 {
    font-family: Raleway;
    font-size: 31px;
    line-height: 37px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__subHeading-1 {
    font-family: Raleway;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__subHeading-2 {
    font-family: Raleway;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__body-1 {
    font-family: Noto Sans;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__body-2 {
    font-family: Noto Sans;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__body-3 {
    font-family: Noto Sans;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__buttonText {
    font-family: Noto Sans;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
  &__caption {
    font-family: Noto Sans;
    font-size: 12.8px;
    line-height: 17px;
    letter-spacing: 0em;

    &__regular {
      font-weight: 400;
    }
    &__medium {
      font-weight: 500;
    }
    &__semiBold {
      font-weight: 600;
    }
    &__bold {
      font-weight: 700;
    }
  }
}
