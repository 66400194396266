@import '../../assets/styles/index.scss';

.emailVerification {
  @extend .d-flex;
  @extend .d-flex-col;

  gap: 14px;
  &__field {
    &__password {
      position: relative;
      &__eyeIcon {
        position: absolute;
        top: 1.2rem;
        right: 1rem;
        @extend .cursor-pointer;
      }
    }

    &__resend {
      @extend .d-flex;
      flex-direction: row-reverse;
      @extend .w-full;
      justify-content: space-between;
      @extend .align-items-center;

      &__link {
        @extend .cursor-pointer;
        color: $primary-700;
      }
      &__disabledLink {
        cursor: not-allowed;
        color: $primary-400;
      }
    }
  }
}
