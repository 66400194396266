$mobile: 600px;
$tablet: 768px;
$tablet_lg: 1024px;
$desktop: 1280px;
$desktop_sm: 992px;

@mixin minTablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin minTabletLarge {
  @media screen and (min-width: $tablet_lg) {
    @content;
  }
}
