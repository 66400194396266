@mixin tooltipTextStyle {
  color: #fff;
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin tooltipContainer {
  background: $primary-800;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  bottom: calc(100% - 16px);
  &::after {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    border-top: 8px solid $primary-800;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
  &--is-on-left {
    &::after {
      top: 50%;
      left: initial;
      right: -11px;
      transform: translateY(-50%) rotate(-90deg);
    }
  }
  &--is-on-right {
    &::after {
      top: 50%;
      left: 0;
      transform: translateX(-11px) translateY(-50%) rotate(90deg);
    }
  }
  &--is-on-bottom {
    &::after {
      top: -7px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }
  }
}
