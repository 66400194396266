@import '../../assets/styles/index.scss';

.forceChangePassword {
  @extend .d-flex;
  @extend .d-flex-col;
  gap: 14px;
  &__field {
    position: relative;
    &__eyeIcon {
      position: absolute;
      top: 2.6rem;
      right: 1rem;
      @extend .cursor-pointer;
    }
  }
}
