@import '../../../assets/styles/index.scss';

.custom-input-wrapper {
  position: relative;
  display: inline;

  .delete-group-modal__input {
    padding-left: 10px;
  }

  .placeholder-with-bold-text {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-family: Noto Sans;
    color: $black-800;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
  }
  .placeholder-with-bold-text span {
    font-weight: 600;
  }
}
