@import '../../../assets/styles/index.scss';

.errorVisible {
  visibility: visible;
}

.errorInvisible {
  visibility: hidden;
  color: transparent;
}

.common-text-input {
  &__label {
    color: $black-900;
  }
  &__input-field {
    position: relative;
    &__input {
      &--is-currency {
        padding-left: 52px;
        &:focus {
          padding-left: 52px;
        }
      }
    }
    &__currency {
      position: absolute;
      width: 40px;
      left: 1px;
      top: 7.1px;
      bottom: 1px;
      border-radius: 8px 0px 0px 8px;
      background: $light-blue-5;
      display: flex;
      p {
        margin: auto;
        color: #374151;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &__error {
    color: $errors-800;
    padding-top: 5px;
  }
}

.container_input_tooltip {
  display: flex;
  gap: 8px;
}

.additional-label {
  color: $grey-1;
  font-family: Noto Sans;
  font-size: 12.8px;
  font-weight: 400;
  line-height: 17.43px;
  margin-left: 4px;
}
