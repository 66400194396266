@import '../../../assets/styles/globals/index.scss';

@mixin tooltipTextStyle {
  color: #fff;
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-field-tooltip {
  background: $primary-800;
  border-radius: 5px;
  padding: 8px 7px 8px 4px;
  width: fit-content;
  position: absolute;
  bottom: calc(100% - 12px);
  right: -16px;
  p {
    @include tooltipTextStyle;
    text-align: center;
    margin-bottom: 4px;
    white-space: nowrap;
  }
  ul {
    @include tooltipTextStyle;
    list-style: initial;
    padding-left: 30px;
    li {
      white-space: nowrap;
    }
  }
  &::after {
    position: absolute;
    top: 100%;
    right: 25px;
    content: '';
    border-top: 8px solid $primary-900;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
}

@media screen and (min-width: $tablet) {
  .input-field-tooltip {
    right: calc(100% + 16px);
    bottom: auto;
    &::after {
      top: 10px;
      bottom: auto;
      right: -16px;
      border-right: 8px solid transparent;
      border-left: 8px solid $primary-900;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }
}
