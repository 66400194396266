@import '../../../../assets/styles/index.scss';

.email-list-actions {
  @include flexRow;
  align-items: center;
  column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  button {
    @include flexRow;
    align-items: center;
    column-gap: 8px;
    padding: 0 21px;
  }
  .remove {
    @include lightRedButton;
    width: 128px;
    height: 40px;
  }
  .remove-add-recipient-container {
    display: flex;
    gap: 16px;
  }
  .email-integration {
    color: $black-2;
  }
  .add-recipients {
    @include blueButton;
    width: 174px;
    height: 40px;
    &:disabled {
      opacity: 50%;
    }
  }
  .add-recipients-d-none {
    display: none;
  }
  &__modal {
    display: flex;
    .cloud-accounts-connect-email {
      margin: auto;
      width: 900px;
    }
  }
}
@media screen and (max-width: $tablet) {
  .email-list-actions__modal .cloud-accounts-connect-email {
    width: 90%;
    height: 90%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: $mobile) {
  .plusicon_inside_btn {
    display: none;
  }
  .email-list-actions .add-recipients {
    width: auto;
  }
}
