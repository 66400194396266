@import '../../assets/styles/index.scss';

.cardWrapper {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 120px);
  background-color: $white;
  /* Replace with desired background color */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px;
  font-family: Noto Sans;
  overflow: visible;

  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/png/cardBGWave.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.6;
  }
}

.accountId {
  color: $primary-700;
}

.azureForm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  background: transparent;

  &__formWrapper {
    font-size: normal;
    white-space: normal;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__input {
      background: transparent;
    }
  }
}

.policies-styling {
  @include customScroll;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
}
.azure_btn,
.azureForm__formWrapper__disabledBtn {
  margin-top: 10px;
}

.azure-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 64px;
}
.input-inner-container {
  width: 75%;
}
.azure-redirect-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding-bottom: 24px;
}
.azure_url {
  font-size: 16px !important;
  color: $blue-3 !important;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.cloud-accounts__connect-account-form {
  @media screen and (min-width: $desktop) {
  }
}

@media (min-width: 640px) {
  .azureForm {
    .__formWrapper {
      flex-direction: row;
      gap: 1rem;
    }
  }
}

@media (min-width: 1024px) {
  .azureForm {
    &__formWrapper {
      flex-direction: column;
      gap: 32px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .azureForm__formWrapper {
    gap: 5px;
  }
}

@media screen and (max-width: $tablet) {
  .azure-input-container {
    flex-direction: column;
    gap: 16px;
  }
  .input-inner-container {
    width: 100%;
  }
}
.azure-popup-img {
  width: 100% !important;
  height: 100% !important;
}
.steps-azure-popup-title,
.modalURL {
  display: flex;
  font-size: clamp(12px, 1.1vw, 1.1vw);
  line-height: normal;
}
.steps-azure-popup-title .steps-number {
  width: 5vw;
  min-width: 50px;
}
.steps-azure-popup {
  width: 100%;
  font-weight: 400;
}
.more-details {
  z-index: 2;
}
.more-details button {
  color: $blue-3;
  text-decoration: underline;
  font-weight: 600;
}
.azure-modal-box {
  padding: 0 !important;
}
.connect-azure-modal-heading {
  font-size: clamp(18px, 1.5vw, 1.5vw);
  margin-bottom: 0 !important;
}
.azure_Modal_URL {
  font-size: clamp(12px, 1.1vw, 1.1vw) !important;
  line-height: normal;
}
