@import '../../../assets/styles/globals/index.scss';

.MuiModal-root#common-confirm-modal {
  display: flex;
}

@mixin confirmModalText {
  color: $normal-text;
  text-align: center;
  font-style: normal;
}

.common-confirm-modal {
  width: 412px;
  padding: 24px 24px 70px 24px;
  background: #fff;
  outline: none;
  margin: auto;
  border-radius: 16px;
  font-family: Noto Sans;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__heading {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -14px;
    img {
      cursor: pointer;
    }
  }
  &__image {
    width: 48px;
    height: 48px;
    align-self: center;
    cursor: pointer;
  }
  &__title {
    @include confirmModalText;
    color: $black-1000
  }
  &__description {
    @include confirmModalText;
    align-self: center;
    width: 281px;
    overflow-wrap: break-word;
    color: $black-800
  }
  &__confirm-button {
    @include blueButton;
    padding: 6px 20px;
    font-weight: 500;
    width: fit-content;
    align-self: center;
  }
}

@media screen and (min-width: $tablet) {
  .common-confirm-modal {
    width: 505px;
    padding: 17px 17px 49px 17px;
  }
}

@media only screen and (max-width: $mobile) {
  .common-confirm-modal {
    width:90%;
    height: fit-content;
  }
}