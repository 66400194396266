@import '../../../assets/styles/index.scss';

.MuiModal-root#account_delete_modal {
  display: flex;
}

.account_delete_modal {
  background: #fff;
  outline: none;
  margin: auto;
  border-radius: 16px;
  width: 550px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Noto Sans;

  &__red {
    background: $error-600-light;
    padding: 24px;
    display: flex;
    justify-content: center;

    h2 {
      color: $white;
      text-align: center;
      width: 391px;
    }
  }

  &__white {
    background: #fff;
    padding: 24px; //trbl
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      color: $normal-text;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      margin: auto;
    }

    h3 {
      color: $normal-text;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      margin: auto;

      .org-id {
        color: $primary-600;
      }
    }

    input {
      @include inputField;
      width: 100%;
      margin: auto;
      border-radius: 8px;
      border: 1px solid $black-600;
      height: 43px;
    }

    &__buttons {
      display: flex;
      gap: 16px;
      padding: 25px 0 10px 0;
      margin: auto;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .account_delete_modal {
    width: 90%;
    height: fit-content;
  }
}
