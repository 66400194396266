@import '../../../assets/styles/index.scss';
@import '../../../assets/styles/index.scss';

.MuiModal-root#common-daily-modal {
  display: flex;
}

.common-daily-modal {
  width: 318px;
  padding: 24px 36px;
  background: #fff;
  outline: none;
  margin: 92px auto auto auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    img {
      cursor: pointer;
    }
    h3 {
      font-family: Raleway;
      font-size: 24px;
      font-weight: 700;
      color: $normal-text;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    gap: 33px;

    &__list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__title {
        color: $primary;
        font-family: Noto Sans;
        font-size: 14px;
        font-weight: 600;
      }
      &__items {
        height: 250px;
        width: 120px;
        @include customScroll;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        div {
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          p {
            color: $normal-text;
            font-family: Noto Sans;
            font-size: 14px;
            font-weight: 400;
          }
        }

        &__time {
          width: 150px;
          padding-right: 10px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}

@media screen and (min-width: $tablet) {
  .common-daily-modal {
    width: 564px;
    padding: 24px 36px;
    &__image {
      margin-bottom: 21px;
    }
  }
}
