@import '../../../assets/styles/index.scss';

div.permissions-accordion {
  &__head {
    padding: 24px 11px;
    margin-bottom: 0;
    border-radius: 16px;
    &--is-open {
      margin-bottom: 0 !important;
    }
  }
}

.permissions-module {
  &__head {
    display: flex;
    span {
      color: $black-3;
      padding: 12.5px 11px;
      background: $primary-200;
      word-break: break-word;
      &.permission {
        flex-basis: 45%;
      }
      &.access {
        flex-basis: 55%;
        text-align: center;
      }
    }
  }
  &__item {
    display: flex;
    .permission {
      color: $black-3;
      padding: 12.5px 11px;
      flex-basis: 45%;
      word-break: break-word;
    }
    &__input {
      flex-basis: 55%;
      @include flexJustifyCenter;
      align-self: center;
      cursor: pointer;
      &--is-hidden {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  div.permissions-accordion {
    &__head {
      padding: 19px 26px;
    }
  }
  .permissions-module {
    padding: 0 26px 19px 26px;
    &__head {
      span {
        &.permission {
          flex-basis: 50%;
          padding: 12.5px 11px 12.5px 20px;
        }
        &.access {
          flex-basis: 50%;
        }
      }
    }
    &__item {
      .permission {
        flex-basis: 50%;
        padding: 12.5px 11px 12.5px 20px;
        font-size: 14px;
      }
      &__input {
        flex-basis: 50%;
      }
    }
  }
}

.permissions-module__item .description {
  display: inline-block;
  padding: 4px 0 0 0;
}
