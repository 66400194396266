@import '../../../assets/styles/index.scss';

@mixin createStackStepStyles {
  &__content {
    @include flexCol;
    row-gap: 12px;
    padding-right: 1rem;

    @include minTabletLarge {
      padding-bottom: 1rem;
    }

    &__sub-heading {
      display: block;
      width: fit-content;
      padding: 0.5rem 0;

      @include minTabletLarge {
        padding: 1rem 0;
      }
    }

    &__steps {
      @include flexCol;
      row-gap: 12px;
    }

    &__img-large-screen {
      display: none;

      @include minTablet {
        display: block;
      }
    }
  }

  &__img-small-screen {
    display: inline-block;
    margin-left: -0.25rem;

    @include minTablet {
      display: none;
    }
  }
}

.connect-account-steps {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 24px 40px 24px;
  margin-bottom: 8px;
  overflow: auto;
  border-radius: 10px;
  font-size: 16px;

  @media (min-height: $desktop) {
    height: 82vh;
    padding-left: 32px;
    margin-bottom: 0;
  }
  @include customScroll;
  .aws_image {
    width: 611px;
    height: 174px;
    border-radius: 6px;
  }

  &__steps {
    @include flexCol;
    row-gap: 10px;

    &__step {
      @include flexCol;
      row-gap: 12px;

      &__content {
        padding-left: 2rem;
        padding-right: 1.5rem;
        margin-left: 1.25rem;
        border-left: 1px solid $black-300;

        @include minTabletLarge {
          padding-right: 5rem;
        }
      }
    }

    .create-stack-step {
      @include createStackStepStyles;
    }
  }
}
.aws_activate_trusted {
  width: 90%;
  height: auto;
  border-radius: 6px;
}
.stepTitle {
  color: $black-800;
  font-weight: 600;
}

.stepDesc {
  color: $black-700;
  font-weight: 400;
  width: 66%;

  &__accountId {
    color: $black-700;
    font-size: 14px;
    font-weight: 600;

    img {
      cursor: pointer;
      margin-left: 0.5rem;
      display: inline-block;
    }
  }
}

.bottomCardWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: $tablet) {
    justify-content: flex-end;
  }

  @media (min-width: $desktop) {
    margin-right: 2px;
    margin-bottom: 2px;
    bottom: 0;
  }

  &__bottomCard {
    width: 100%;
    padding: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (min-width: $tablet) {
      flex-direction: row;
      gap: 0;
    }

    background-color: $white;

    &__btnContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      .connect_btn {
        padding: 0 23px;
        white-space: nowrap;
      }

      .confirm_btn {
        padding: 0 2rem;
        align-self: center;
        @include minTabletLarge {
          padding: 0 2.5rem;
        }
      }

      &__confirmBtn {
        background-color: $success-800;
        cursor: pointer;
      }

      &__confirmBtnDisabled {
        background-color: $success-800;
        opacity: 0.5;
      }
    }
  }
}

.bottomCardWrapper__bottomCard__btnContainer .aws_disconnect_btn {
  width: 117px;
}
@media (max-width: $mobile) {
  .bottomCardWrapper__bottomCard__btnContainer .aws_disconnect_btn {
    width: 100% !important;
  }
}
