@import '../../../assets/styles/index.scss';

.card {
  color: $normal-text;
  font-family: Noto Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  border-radius: 16px;
  background: inherit;
  color: $black-800;

  &__img {
    border-radius: 50%;
    box-sizing: content-box;
    box-shadow: 0px 8px 30px rgba(18, 18, 18, 0.1);
    transition: filter 0.3s ease;

    &:hover {
      filter: grayscale(100%);
    }
  }

  .heading {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: $black-900;
  }

  .description {
    font-size: 12.8px;
    width: 340px;
    text-align: center;
  }
}
