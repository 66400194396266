@import '../../../assets/styles/index.scss';

@mixin textStyling {
  color: $black-800;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.delete-group-modal {
  width: 460px;
  background: #fff;
  border-radius: 16px;
  padding: 27px 19px 32px 30px;
  margin: 92px auto auto auto;
  outline: none;
  @include flexCol;
  row-gap: 24px;
  color: $black-1000;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: $normal-text;
      font-family: Raleway;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  &__content {
    color: $black-800;
    &__description {
      margin-bottom: 20px;
      p {
        @include textStyling;
        margin-bottom: 10px;
      }
    }
    ol {
      list-style: number;
      padding-inline-start: 20px;
      li {
        @include textStyling;
      }
    }
  }
  &__form {
    input {
      @include inputField;
      margin-bottom: 6px;
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 13px;
      button {
        width: 198px;
        &.cancel {
          height: 35px;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .delete-group-modal {
    width: 460px;
    padding: 16px 24px;

    &__form {
      input {
        @include inputField;
        width: 328px;
        margin-bottom: 22px;
      }
    }
  }
}
@media screen and (min-width: $tablet) {
  .delete-group-modal {
    &__form {
      input {
        @include inputField;
        width: 100%;
        margin-bottom: 24x;
      }
    }
  }
}
@media only screen and (max-width: $mobile) {
  .delete-group-modal {
    width: 90%;
    height: fit-content;
  }
}
