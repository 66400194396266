@import '../../../assets/styles/index.scss';

.formContainer {
  display: flex;
  justify-content: center;
  width: 550px;
  padding: 24px;

  &__card {
    background: $white;
    border-radius: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    &__title {
      text-align: center;
      color: $black-900;
    }
    &__children {
      width: 100%;
    }
  }
}
//Style @  Large Tablet
@media screen and (max-width: 1024px) {
  .formContainer {
    width: 100%;
  }
}
