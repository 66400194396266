@import '../../assets/styles/index.scss';

.somethingWentWrong {
  padding: 34px 29px 28px 33px;
  background: $light-blue;
  width: 100%;
  height: 100vh;

  &__container {
    border-radius: 10px;
    background: #fff;
    width: 100%;
    height: -webkit-fill-available;
    display: flex;
    gap: 39px;
    justify-content: center;
    align-items: center;
    color: $primary;

    h1 {
      font-variant-numeric: lining-nums proportional-nums;
      font-family: Raleway;
      font-size: 96px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: 96px;
    }
    &__description {
      display: flex;
      flex-direction: column;
      gap: 29px;
      h4 {
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &__buttons {
        display: flex;
        gap: 14px;
      }
    }
  }
}
