@import '../../../../assets/styles/index.scss';

.molecule-summary-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 24px;
  padding-bottom: 5px;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    flex: 1;
    gap: 16px;
    & > * {
      white-space: nowrap;
    }
    &__left {
      display: flex;
      align-items: center;
      column-gap: 8px;
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 8px;
        background: $primary-200;
      }
      &__label {
        color: $black-800;
        span {
          color: $black-700;
        }
      }
    }
    &__right {
      &__value {
        color: $primary-600;
      }
    }
  }
}
.cloud-account-summary-stats {
  .molecule-summary-stats__item:nth-child(6) {
    .molecule-summary-stats__item__right__value {
      color: $errors-700 !important;
    }
  }
}
@media (max-width: $desktop) {
  .summary_card_container {
    display: flex;
    gap: 2px;
    flex-direction: column;
  }
}
