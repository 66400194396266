.file-input-label {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #6e6e6e;
  height: 48px;
  padding: 12px 0px 8px 35px;
  padding-inline-end: 35px;
  margin-top: 6px;
  display: inline-block;
  cursor: pointer;
}
.file-input-label:hover {
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
}

.file-input-icon {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -6px;
}

.file-input-text {
  font-family: 'Noto Sans';
  color: #6e6e6e;
  font-size: 12.8px;
  line-height: 17.43px;
}
.file-input-text span {
  font-family: 'Noto Sans';
  color: #257ad2;
  font-size: 12.8px;
  line-height: 17.43px;
}

.file-input-text-mobile {
  font-family: 'Noto Sans';
  color: #6e6e6e;
  font-size: 12.8px;
  line-height: 17.43px;
}

/*Style @Mobile*/
@media only screen and (max-width: 600px) {
  .file-input-text {
    display: none;
  }
  .file-input-label {
    padding: 12px 0px 8px 30px;
  }
  .file-input-icon {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 600px) {
  .file-input-text-mobile {
    display: none;
  }
}
