@import '../../../assets/styles/index.scss';

@mixin statusVariants {
  &--is-inactive {
    filter: grayscale(1);
  }
  &--is-inprogress {
    filter: grayscale(1);
  }
  &--is-unreachable {
    filter: grayscale(1);
    box-shadow: (0px 0px 4px rgba(209, 41, 61, 0.25));
  }
  &--is-unknown {
    filter: grayscale(1);
  }
}

@mixin borderVariants {
  &--is-active {
    border: 1.5px solid $green-3;
    box-shadow: 0px 0px 4px 0px rgba(26, 173, 149, 0.25);
  }
  &--is-failed {
    border: 1.5px solid $red-1;
  }
  &--is-inactive {
    border: 1.5px solid $light-grey-1;
  }
  &--is-inprogress {
    border: 1.5px solid $light-grey-1;
  }
  &--is-unreachable {
    border: 1.5px solid $light-grey-1;
    box-shadow: (0px 0px 4px rgba(209, 41, 61, 0.25));
  }
  &--is-unknown {
    border: 1.5px solid $light-grey-1;
  }
}

@mixin pulseAnimation {
  @keyframes pulseAnimation {
    0% {
      box-shadow: (0px 0px 2px rgba(37, 122, 210, 1));
    }
    20% {
      box-shadow: (0px 0px 7px rgba(37, 122, 210, 1));
    }
    35% {
      box-shadow: (0px 0px 2px rgba(37, 122, 210, 1));
    }
    55% {
      box-shadow: (0px 0px 10px rgba(37, 122, 210, 1));
    }
    100% {
      box-shadow: (0px 0px 2px rgba(37, 122, 210, 1));
    }
  }
}

.configure-notifications {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  .slack-button,
  .msteams-button,
  .email-button {
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;
    &__icon {
      height: 40px;
      width: 40px;
      border: 1.5px solid $light-grey-1;
      border-radius: 50%;
      display: flex;
      img {
        margin: auto;
        width: 23px;
      }
      &:hover {
        box-shadow: 0px 0px 4px 0px rgba(26, 173, 149, 0.25);
      }
      @include statusVariants;
    }
    &__add {
      // positioning
      position: absolute;
      right: -16%;
      top: -26%;
      background: #fff;
      z-index: 1;

      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 1px solid $light-grey-1;
      @include flexJustifyCenter;
      @include flexAlignItemsCenter;

      transition: all 0.2s;

      @include pulseAnimation;
      animation: pulseAnimation ease-in-out 3s infinite;
      &:hover {
        box-shadow: 1px 1px 1px 0px rgba(26, 173, 149, 0.25);
      }
      svg {
        height: 22px;
        width: 22px;
        margin: auto;
      }
    }
    .button-border {
      @include borderVariants;
    }
  }
}
@media only screen and (max-width: $mobile) {
  .configure-notifications .slack-button,
  .configure-notifications .msteams-button,
  .configure-notifications .email-button {
    height: 35px;
    width: 40px;
  }
}
