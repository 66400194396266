@import '../../assets/styles/index.scss';

.welcomeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Noto Sans;
  gap: 32px;

  &__gradientCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    gap: 16px;
    background: linear-gradient(154.16deg, $gradient-100 30%, $gradient-200, $white) !important;

    &__integration {
      width: 64px;
      height: 64px;
    }

    &__welcomeHeader {
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }

    &__welcomeText {
      color: $black-700;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      width: 100%;
    }

    &__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 180px;
      gap: 8px;
    }
  }

  &__cardWrapper {
    @include flexCol;
    align-items: center;
    width: 100%;
    gap: 32px;
    &__gradientCard {
      &__welcomeText {
        width: 530px;
      }
    }
    &__card {
      padding: 40px;
      gap: 16px;
      height: 270px;
      width: 100% !important;
      .description {
        width: 100% !important;
      }
    }
  }
}

.welcomeContainer__cardWrapper {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media screen and (min-width: $tablet) {
  .welcomeContainer {
    &__gradientCard {
      &__welcomeText {
        width: 630px;
      }
    }
    &__cardWrapper {
      @include flexRow;
      &__card {
        width: 50%;
        .description {
          width: 340px;
        }
      }
    }
  }
}
.welcomeContainer__cardWrapper__card .description {
  font-family: Noto Sans;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19.07px;
  text-align: center;
}

// Style @ Mobile
@media only screen and (max-width: 600px) {
  .welcomeContainer__cardWrapper__card .description {
    width: 100% !important;
  }
  .welcomeContainer {
    margin-bottom: 42px;
  }
}
// Style @ M and L Tablet
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .welcomeContainer__cardWrapper__card .description {
    width: 100% !important;
  }
}
