@import '../../../assets/styles/index.scss';

.common-loader {
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  animation: spin 1s linear infinite;
  border-radius: 9999px;
  border-width: 3px;
  border-style: solid;
  border-color: currentColor;
  border-right-color: transparent;
  vertical-align: -0.125em;

  span {
    position: absolute !important;
    margin: -1px !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    border-width: 0px !important;
    padding: 0px !important;
    clip: rect(0, 0, 0, 0) !important;
  }
}
