@mixin loaderSkeleton {
  @keyframes loadingAnimation {
    0% {
      background-position: 0%;
    }
    25% {
      background-position: 50%;
    }
    50% {
      background-position: 100%;
    }
    75% {
      background-position: 50%;
    }
    100% {
      background-position: 0%;
    }
  }

  display: inline-block;
  border-radius: 6px;
  position: relative;
  border: none !important;
  pointer-events: none;
  cursor: none;
  & > * {
    visibility: hidden;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      90deg,
      #f1efef -24.18%,
      #f9f8f8 50.26%,
      #e7e5e5 114.84%
    ) !important;
    border-radius: inherit;
    border: none;
    outline: none;
    animation: loadingAnimation 3s infinite linear;
    background-size: 400% 100%;
    transform: scale(1.01);
    height: initial;
  }
}
