@import '../../assets/styles/index.scss';

.settings {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.settings_loading {
  .apply-loader {
    @include loaderSkeleton;
  }
}
