$primary: #33475b;
$normal-text: #374151;
$light-blue: #f7f8fb;
$light-blue-1: #e9edf5;
$light-blue-2: #dfe3e8;
$light-blue-3: #e7f5fa;
$light_blue-4: #ebf0fa;
$light-blue-5: #595959;
$light-blue-5: #d2e8f1;

// black colors
$black-2: #1d1d1d;
$black-3: #121212;

// grey colors
$light-grey-1: #c4c4c4;
$light-grey-2: #727272;
$light-grey-3: #d9d9d9;
$light-grey-4: #919eab;
$light-grey-5: #e8e8e8;
$light-grey-6: #595959;
$grey-1: #6e6e6e;
$grey-2: #e3ecfb;
$dark-grey-400: #212b36;

$red-toast-stroke: #d1293d;
$light-green-1: #e1fcef;

// green colors
$green-1: #128c83;
$green-2: #14804a;
$green-3: #1aad95;
$green-4: #3a8e03;

// blue colors
$blue-1: #434a56;
$blue-2: #2264e5;
$blue-3: #165baa;
$blue-4: #144c90;
$blue-5: #144278;

$orange-1: #fcf2e6;
$orange-2: #aa5b00;

//red-colors
$light-red-1: #ffedef;
$light-red-2: #fceff0;
$red-1: #d1293d;

//orange-colors
$orange-3: #ec7d17;

// white colrs
$white-1: #fff;
$white-2: #f2f2f2;
