@import '../../../assets/styles/index.scss';

.authFormContainer {
  @extend .d-flex;
  @extend .justify-content-center;
  width: 550px;
  min-height: max-content;
  padding: 24px;
  cursor: auto;

  &__card {
    @extend .bg-white;
    border-radius: 16px;
    @extend .w-full;
    @extend .d-flex;
    @extend .d-flex-col;
    gap: 24px;
    height: 100%;

    &__back {
      @extend .d-flex;
      @extend .text-black-900;
      @extend .align-items-center;
      @extend .cursor-pointer;
      width: max-content;
    }

    &__title {
      text-align: left;
      @extend .text-black-900;
    }

    &__component {
      width: 100%;
    }
  }
}

.authFormContainer__card__back {
  align-items: center;
}

//Responsive Mobile & Tablet

@media screen and (max-width: 600px) {
  .authFormContainer {
    width: 100%;
  }
}
