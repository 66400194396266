@import '../../../assets/styles/index.scss';

.view-more-list-modal {
  position: fixed;
  z-index: 99999;
  width: fit-content;
  border-radius: 4px;
  word-break: keep-all;
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 14px 16px 13px 17px;
    border-radius: 8px;
    max-height: 259px;
    width: fit-content;
    overflow-y: scroll;
    background: #fff;
    box-shadow: 0px 8px 60px 0px rgba(18, 18, 18, 0.15);
    @include customScroll;
    &::-webkit-scrollbar {
      width: 7px;
    }
  }
  &__item {
    font-family: Noto Sans;
    font-size: 12.8px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.43px;
    border-radius: 4px;
    padding: 2px 10px;
    word-break: break-all;
    width: 149px; // width in accordance with figma.
  }
}
