@import '../../../assets/styles/index.scss';

@mixin customButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary {
  @include blueButton;
  @include customButton;
  gap: 10px;
  width: 98px;
}

.secondary {
  @include whiteButton;
  @include customButton;
  gap: 10px;
  width: 98px;
}

.tertiary {
  @include customButton;
  @include tertiaryButton;
  gap: 10px;
  width: 75px;
  border: none;
  background: none;
}

.disabled {
  @include whiteButton;
  @include customButton;
  background-color: $light-grey-1;
  color: $normal-text;
  width: 98px;
}

.delete {
  @include blueButton;
  @include customButton;
  background: $error-600-light;
  color: white;
  width: 159px;
}

.red {
  @include redButton;
}

.lightRed {
  @include lightRedButton;
}

.custom-button {
  &--is-disabled {
    opacity: 50%;
    &:hover {
      box-shadow: none;
    }
    cursor: not-allowed;
  }
}

@media screen and (min-width: $tablet) {
  .primary {
    width: 134px;
  }
  .secondary {
    width: 134px;
  }
  .tertiary {
    width: 134px;
  }
}

.create-team-btn img {
  filter: contrast(1);
}
