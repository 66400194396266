// this is a utility file for common styles --> utility classes
@import './colors/v3';

//display
.d-flex {
  display: flex;
}

.d-flex-col {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

// Added as it does not introduce an additional class
// Existing class can be extended using this mixin
// convenient to inspect with developer tools
@mixin flex {
  display: flex;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexJustifyCenter {
  display: flex;
  justify-content: center;
}

@mixin flexAlignItemsCenter {
  display: flex;
  align-items: center;
}

@mixin flexJustifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flexJustifyFlexStart {
  display: flex;
  justify-content: flex-start;
}

@mixin flexJustifyFlexEnd {
  display: flex;
  justify-content: flex-end;
}

//color

.text-black-700 {
  color: $black-700;
}
.text-black-800 {
  color: $black-800;
}

.text-black-900 {
  color: $black-900;
}

.text-black-1000 {
  color: $black-1000;
}

.text-primary-800 {
  color: $primary-800;
}
.text-primary-600 {
  color: $primary-600;
}
.text-primary-700 {
  color: $primary-700;
}

.bg-white {
  background-color: $white;
}

//width
.w-full {
  width: 100%;
}

//cursor
.cursor-pointer {
  cursor: pointer;
}
// input type search icon hover
input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}
.cursor-context-menu {
  cursor: context-menu;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-image: url('../../../assets/images/svg/closeIcon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
}

//add-user|add-group | form

.addContainer {
  padding-top: 16px;
  margin-top: 16px;
  height: calc(100vh - 155px) !important;
  cursor: auto;
}

.addCardForm {
  @extend .d-flex;
  @extend .d-flex-col;
  width: 648px;
  height: 360px !important;
  margin: auto;
  margin-top: 3%;
  padding: 24px;
  cursor: auto;
  gap: 14px;
}

.addCardFormHeader {
  @extend .d-flex;
  @extend .d-flex-col;
  gap: 8px;
}

.addButtonsGroupFooter {
  @extend .d-flex;
  @extend .justify-content-center;
  gap: 24px;
}

.align-items-center,
.signUpForm__checkmark {
  gap: 8px;
}

//Style @  Large Screen
@media screen and (max-width: 1024px) {
  .align-items-center,
  .signUpForm__checkmark {
    align-items: flex-start;
  }
}
