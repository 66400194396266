@import '../../../assets/styles/index.scss';

.table-column-statuses {
  display: flex;
  column-gap: 5px;
  &__status {
    display: flex;
    width: fit-content;
    align-items: center;
    column-gap: 5px;
    padding: 4px 16px;
    border-radius: 4px;
    width: fit-content;
    cursor: pointer;
    &--active {
      background: $success-200;
      color: $success-800;
    }
    &--inactive,
    &--paused {
      background: $black-200;
      color: $black-800;
    }
    &--failed,
    &--unreachable {
      color: $errors-700;
      background: $errors-200;
    }
    &--inprogress {
      color: $warning-800;
      background: $errors-100;
    }
    &--connected {
      background: $light-green-1;
      color: $green-1;
    }
    img {
      height: 12px;
      width: 12px;
    }
  }
  &__tooltip-icon {
    cursor: pointer;
  }
}

@media screen and (min-width: $desktop) {
  .table-column-statuses {
    &__tooltip-icon {
      display: none;
    }
  }
}

.MuiTooltip-popper {
  text-align: center;
}
