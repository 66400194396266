@import '../../../assets/styles/index.scss';

.permissions-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin: 18px 11px;
  padding: 0 16px;
  &__heading {
    color: $black-900;
    margin: 16px 0;
  }
  &__permissions {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 27px;
  }
  &__navigation {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    background: #fff;
    padding-bottom: 27px;
    &__backward {
      display: flex;
      column-gap: 18px;
      .cancel {
        @include whiteButton;
        width: 98px;
      }
      .goback {
        @include whiteButton;
        width: 75px;
        border: none;
        background: none;
        &:hover {
          box-shadow: none;
          background: $light-blue-3;
        }
      }
    }
    &__forward {
      .next {
        @include blueButton;
        width: 98px;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .permissions-container {
    &__navigation {
      &__backward {
        column-gap: 13px;
        .cancel {
          width: 200px;
        }
        .goback {
          width: 200px;
        }
      }
      &__forward {
        .next {
          width: 200px;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .permissions-container {
    height: calc(100% - 52px);
  }
}
