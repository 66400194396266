@import '../../../../assets/styles/index.scss';

.slack {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  height: calc(100vh - 40px);
  margin-top: 16px;

  .slackContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__slackHeaderView {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__header {
        color: $black-900;
      }
    }
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &__confirmBtn {
        background-color: $success-800;
        color: $white;
      }

      &__confirmBtnDisabled {
        background-color: $success-800;
        opacity: 0.5;
        color: $white;
        border-width: 0px;
      }
    }
  }
}

.noDataWrapper {
  margin-top: 16px;
}

.connectSteps {
  margin-top: 16px;
  padding: 24px 24px 40px 24px;

  &__stepTitle {
    color: $black-700;
  }

  &__descDiv {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    gap: 14px;
    color: $black-700;
  }
}

.key {
  font-family: Noto Sans;
  width: max-content;
}

.value {
  font-family: Noto Sans;
  word-wrap: break-word;
  width: max-content;
}

.slackButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  .disconnect-button {
    width: 149px;
  }
  .recofig-button {
    width: 156px;
  }
}

.slack_loading {
  .apply-loader {
    @include loaderSkeleton;
    display: flex;
    padding: 0 5px;
  }
}

// @Style @ tablet
@media only screen and (max-width: $tablet) {
  .slack {
    .connectStep {
      .description {
        width: inherit !important;
      }
    }
  }
}

// @Style @ Mobile
@media only screen and (max-width: $mobile) {
  .slack .connectSteps {
    padding: 24px 16px 40px 16px;
  }
  .slack {
    .connectStep {
      padding-right: 10px;
    }
    .slackContainer {
      &__slackHeaderView {
        flex-direction: column;
        gap: 12px;
        &__header {
          width: 100%;
        }
        .slackButtons {
          width: 100%;
          justify-content: flex-start;
          .disconnect-button {
            width: 135px;
          }
          .reconfig-button {
            width: 140px;
          }
        }
      }
    }
    .buttonWrapper {
      .back-button {
        width: 60px !important;
      }
      .visit-button {
        width: 165px !important;
      }
    }
  }
}

.slack-steps-heading {
  margin-bottom: 0 !important;
}
.slack-click-here {
  color: $blue-3;
  text-decoration: underline;
  font-weight: 600;
}
