@import '../../../assets/styles/index.scss';

.slackEmailEmptyView {
  margin-top: 16px;

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 16px;
    height: calc(100vh - 200px);

    &__logo {
      border-radius: 50%;
      box-shadow: 0px 8px 30px rgba(18, 18, 18, 0.1);
    }

    &__heading {
      color: $black-900;
    }

    &__descView {
      color: $black-800;
    }

    &__buttonIcon {
      height: 24px;
      width: 24px;
    }
  }
}

// @Style @ mobile
@media only screen and (max-width: $mobile) {
  .slackEmailEmptyView {
    &__card {
      padding-inline: 12px;
    }
  }
}
