@import '../../../assets/styles/index.scss';

.upload_container {
  &-description {
    color: $black-700;
  }
  &__icon {
    border-radius: 8px;
    background: $primary-100;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--delete {
      background: $errors-100;
    }
  }
  &-profile-image-text {
    color: $black-800;
    font-family: Raleway;
    margin: auto;
    background: $primary-300;
    border-radius: 50%;
    aspect-ratio: 1;
  }
}
