@import '../../../assets/styles/index.scss';

.organization {
  padding: 24px;
  border-radius: 24px;
  min-height: calc(100vh - 210px);

  &_heading {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding-bottom: 16px;

    h1 {
      color: $black-1000;
    }

    &__buttons {
      @include flexRow;
      column-gap: 16px;
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    &_row {
      display: flex;
      width: 100%;
      flex-direction: column;

      @include minTablet {
        flex-direction: row;
      }

      .field {
        width: 100%;
        display: flex;
        gap: 8px;
        flex-direction: column;
        position: relative;
        &__key {
          color: $black-900;
        }
        &__key-edit::after {
          content: '*';
          color: $errors-700;
        }
        &__value {
          color: $black-800;
        }

        .inputClass {
          // width: 363px;
          margin-left: -0px;
          margin-top: 0px;

          @include minTablet {
            width: 100%;
          }

          @include minTabletLarge {
            width: 353px;
          }

          .orgField {
            @include inputField;
            font-size: 14px;
            color: $black-900;
          }

          .search-select {
            padding-left: 0px;
          }
        }

        .orgInputClass {
          padding-right: 8px;

          &__error {
            font-family: Noto Sans;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: #d1293d;
            visibility: visible;
            font-weight: 300;

            &--is-hidden {
              display: none;
            }
          }
        }

        &__select {
          position: relative;

          &__error {
            color: #d1293d;
            font-size: 0.875rem;
            line-height: 1.25rem;

            &--is-visible {
              visibility: visible;
            }

            &--is-invisible {
              visibility: hidden;
            }
          }
        }
        &__country,
        &__currency,
        &__timezone {
          margin-bottom: -2rem;
        }
      }
    }
  }
}
.organization_content_row .field {
  margin-bottom: 40px;
}
.organization_loading {
  .apply-loader {
    @include loaderSkeleton;
    padding: 0px 2px;
  }
}
.org_value {
  width: 100% !important;
  white-space: pre-wrap;
}

@media only screen and (max-width: $mobile) {
  .organization_heading {
    flex-direction: column;
    gap: 16px;
  }
}

.org_name {
  width: 250px !important;
}
