@import '../../assets/styles/index.scss';

.cloud-accounts-account-view {
  height: calc(100vh - 190px);

  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    flex-direction: column;
    row-gap: 10px;
    .email-list-actions {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      column-gap: 15px;
    }
  }
  &__content {
    // height: 100%;
    height: calc(100vh - 180px);
  }

  .editButton {
    width: 100px;
  }
  .pauseButton {
    width: max-content;
    padding: 10px;
    width: 115px;
  }

  .disconnectButton {
    width: 149px;
  }

  .textInput {
    width: 100%;
  }

  .accountInfoButtons {
    display: flex;
    justify-content: flex-end;
    gap: 13px;
  }
}

@media screen and (min-width: $tablet) {
  .cloud-accounts-account-view {
    &__tabs {
      flex-direction: row;
    }
  }
}

//loading classes
.cloud-accounts-account-view__loader {
  .apply-loader {
    @include loaderSkeleton;
    display: flex;
    padding: 0 5px;
  }
}
