@import '../../../assets/styles/index.scss';
.copy-to-clipboard {
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: 4px;
}

.copy-to-clipboard .text-copy {
  color: $black-700;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  color: $black-700;
}

.copy-to-clipboard__icon {
  z-index: 2;
  cursor: pointer;
  margin-left: 6px;
}
