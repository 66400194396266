@import '../../../assets/styles/index.scss';

.cloud-accounts-summary {
  @include flexCol;
  row-gap: 16px;
  margin-bottom: 24px;
  &__title {
    color: $black-900;
  }
  &__head {
    box-shadow: none;
    &--is-open {
      margin-bottom: 0px !important;
    }
  }
  &__content {
    padding: 8px;
    margin-left: -8px;
  }
  .cloud-account-summary-red {
    .molecule-summary-stats__item__left__icon {
      background: $errors-100;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.paperClass {
  background-color: inherit;
}
.cloud-account-summary-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
}

@media (min-width: $mobile) {
  .cloud-account-summary-stats {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}
@media (max-width: $mobile) {
  .cloud-account-summary-stats {
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  }
}

@media (min-width: $tablet) {
  .cloud-account-summary-stats {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }
}

@media (min-width: $desktop_sm) {
  .cloud-account-summary-stats {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }
}

@media (min-width: $desktop) {
  .cloud-account-summary-stats {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }
}
