@import '../../../../assets/styles/globals/index.scss';

.MuiModal-root#common_add_users_in_group_modal {
  display: flex;
  overflow: auto;
}

.common_add_users_in_group_modal {
  @include flexCol;
  overflow-y: auto;
  @include customScroll;
  width: 90%;
  padding: 16px 17px 23px 17px;
  background: #fff;
  outline: none;
  margin: 92px auto auto auto;
  border-radius: 24px;
  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .modal_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    color: $black-900;
    @include customScroll;
  }
  &__search {
    width: 67%;
    @include flexRow;
    column-gap: 16px;
    margin-bottom: 32px;
    &__button {
      width: 154px;
    }
  }
  .add-users-in-group-container {
    .search_and_result_container {
      @include flexCol;
      width: 100%;
      row-gap: 20px;
    }
    &__separator {
      border: 1px solid $primary-200;
    }
    .results_list {
      @include customScroll;
      @include flexRow;
      flex-wrap: wrap;
      gap: 16px;
      overflow-y: auto;
      margin-bottom: 16px;
      .tm-add-group-add-users-to-group {
        margin-right: 0;
        min-width: 245px;
        padding: 12px;
        &__user {
          @include flexCol;
          gap: 4px;
        }
      }
      &__item {
        @include flexRow;
        @include flexAlignItemsCenter;
        column-gap: 8px;
        padding: 8px 12px;
        border-radius: 8px;
        background: $primary-100;
        cursor: pointer;
        span {
          color: $black-900;
        }
        &__close-icon {
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
      }
    }

    .selected_users_list_container {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      margin-top: 25px;
      &__heading {
        @include flexRow;
        column-gap: 8px;
        color: $black-900;
      }
    }
  }
  .cancel_add_buttons {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    padding-top: 20px;
    margin-top: auto;

    .button {
      padding-left: 1rem;
      padding-right: 1rem;
      width: 145px;
    }

    .cancel {
      @include whiteButton;
    }
    .add {
      @include blueButton;
    }
  }
}

@media screen and (min-width: $tablet) {
  .common_add_users_in_group_modal {
    width: 90%;
    height: 523px;
    padding: 24px;
    margin: auto;
    .add-users-in-group-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      overflow-y: auto;
      .search_and_result_container {
        width: 62%;
      }

      .selected_users_list_container {
        width: 31%;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .common_add_users_in_group_modal {
    width: 900px;
    height: 523px;
    padding: 24px;

    .add-users-in-group-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      overflow-y: auto;
      .search_and_result_container {
        width: 530px;
      }

      .selected_users_list_container {
        width: 290px;
        margin-top: 10px;
      }
    }

    .cancel_add_buttons {
      justify-content: center;
      padding-top: 2rem;

      .button {
        width: 200px;
      }
    }
  }
}

.add_users_in_group_modal {
  .search_and_result_container {
    .apply-loader {
      @include loaderSkeleton;
      display: flex;
      padding: 0 5px;
      width: max-content;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .results_list .user_list {
    width: 100%;
  }
  .common_add_users_in_group_modal__search .search_input {
    width: 70%;
  }
  .common_add_users_in_group_modal__search__button {
    width: 30%;
  }
  .common_add_users_in_group_modal__search {
    width: 100%;
    &__input-field {
      width: 70%;
    }
    button {
      min-width: 30%;
      display: block;
    }
  }
}
