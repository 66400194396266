@import '../../../../assets/styles/index.scss';

input.input-search-filter {
  @include inputField;
  cursor: pointer;
  appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px);
}
.input-search-filter--active {
  padding: 12px !important;
  border-radius: 8px;
}
.input-search-filter__results.timezone {
  font-size: 12px;
}
.input-search-filter__results {
  border-radius: 0 0 8px 8px;
}
.input-search-filter__results::-webkit-scrollbar {
  background: $grey-2;
  border-radius: 5px;
  width: 8px;
}
.input-search-filter__results::-webkit-scrollbar-thumb {
  background: $blue-3 !important;
  border-radius: 5px;
  width: 8px;
}
.input-search-filter__results::-webkit-scrollbar-button {
  display: none;
}
.input-search-filter__results {
  @include flexCol;
  row-gap: 2px;
  height: 191px;
  overflow-y: auto;
  li {
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    color: $black-800;
    &:hover {
      color: #fff;
      background: $primary-700;
    }
  }
}

.search-select {
  padding: 0;
  .search-select-inner {
    padding-right: 8px;
    input {
      margin-top: 0;
    }
    &::-webkit-scrollbar {
      background: $grey-2;
      border-radius: 5px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $blue-3 !important;
      border-radius: 5px;
      width: 8px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &__label {
      color: $black-1000;
      margin: 8px 0;
      text-transform: capitalize;
    }
  }
}
input#country,input#state,input#currency,input#timezone {
  margin-bottom: 8px;
}
