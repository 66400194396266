@import '../../../../assets/styles/index.scss';

.add-group-add-user {
  @include flexCol;
  row-gap: 32px;
  margin-top: 32px;
  &__go-back {
    padding: 0 16px;
  }
  .search-selected {
    @include flexCol;
    row-gap: 16px;
    padding-left: 10px;
    padding-right: 10px;

    .add_users {
      width: 100%;
      @include flexCol;
      row-gap: 20px;
      padding: 16px;
      @include searchBar;
      display: flex;
      flex-direction: column !important;
      margin-bottom: 0 !important;
      &__search {
        display: flex;
        column-gap: 16px;
        width: 100%;
      }

      .search_input {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #c4c4c4;
        height: 40px;
        padding-inline: 14px;
        padding-inline-end: 14px;
        padding-left: 42px !important;
      }

      .search_input::placeholder {
        font-family: Noto Sans;
        font-size: 14px;
        color: $black-600;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .search_input:hover {
        box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
      }

      .search_input:focus {
        outline: none; /* Remove default focus outline */
        border: 1px solid#33475B;
        box-shadow: none;
        outline-offset: 2px;
        padding-inline: 14px;
        padding-inline-end: 14px;
      }

      .list-container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100px;
        height: 200px;
        padding-right: 16px;
        row-gap: 8px;
        @include customScroll;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-left: 2px solid #fff;
          border-radius: 800px;
        }
        .tm-add-group-add-users-to-group {
          @include flexRow;
          align-items: center;
          height: 72px;
          padding: 16px;
          margin-right: 0;
          &__user {
            @include flexCol;
            gap: 4px;
          }
        }
      }
    }

    &__divider {
      @include minTablet {
        margin-top: 0.5rem;
      }
    }

    .selected-users-container {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      padding: 16px;
    }

    .selected-users-container_list {
      @include flexRow;
      flex-wrap: wrap;
      overflow-y: auto;
      gap: 16px;
      align-content: flex-start;
      height: 200px;
      @include customScroll;
      row-gap: 5px;
    }
  }
  .add_user_buttons {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 16px;

    .left_buttons {
      display: flex;
      column-gap: 10px;
      button.cancel {
        width: 200px;
      }
      .cancel {
        @include whiteButton;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
      .back {
        @include tertiaryButton;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .create_group_button {
      @include blueButton;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    button.create-group {
      width: 200px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .add-group-add-user {
    .search-selected {
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      flex-direction: row;
      column-gap: 24px;

      .add_users {
        width: 50%;
        row-gap: 16px;
        padding: 16px;
        @include searchBar;
        display: flex;
        flex-direction: column !important;
        margin-bottom: 0 !important;
        &__search {
          display: flex;
          column-gap: 16px;
          width: 100%;
        }
        .search_input {
          width: 70%;
          padding-left: 42px !important;
        }
        .list-container {
          height: 150px;
        }
      }

      .selected-users-container {
        &__heading {
          display: flex;
          align-items: center;
          column-gap: 8px;
          &__count {
            color: $black-900;
          }
        }
        padding: 16px;
        row-gap: 20px;
        width: 50%;
      }
      .selected-users-container_list {
        @include flexRow;
        flex-wrap: wrap;
        gap: 24px;
        height: 200px;
        align-content: flex-start;
      }
    }

    .add_user_buttons {
      display: flex;
      padding-left: 27px;
      padding-right: 27px;
    }
  }
}

.add_user_loading {
  .apply-loader {
    @include loaderSkeleton;
    display: flex;
    padding: 0 5px;
    width: max-content;
  }
}
.add_user_buttons .cancel,
.create_group_button {
  width: 200px;
}
.addGroupContainer {
  min-height: calc(100vh - 175px) !important;
  padding-top: 16px;
  margin-top: 16px;
  overflow-y: auto;
  padding-bottom: 5rem;
  @include customScroll;

  &__addGroupName {
    @extend .addCardForm;
    @extend .justify-content-center;
    row-gap: 24px;
    &__heading {
      color: $black-900;
    }

    &__buttons {
      @extend .addButtonsGroupFooter;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .addGroupContainer__addGroupName {
    box-shadow: none;
    padding: 30px;
    width: 100%;
  }
  .add-group-add-user .search-selected {
    flex-direction: column;
  }
  .add-group-add-user .search-selected .add_users,
  .add-group-add-user .search-selected .selected-users-container {
    width: 100%;
  }
  .add-group-add-user .search-selected .add_users__search {
    width: 100%;
  }
  .add-group-add-user .search-selected .add_users .search_input {
    width: 70%;
  }
  .add-group-add-user .search-selected .add_users button.primary.custom-button {
    width: 30%;
  }
  .selected-users-container__heading {
    display: flex;
    gap: 16px;
  }
}
