@import '../../../assets/styles/globals/index.scss';

.common-archive-restore-modal {
  width: 460px;
  padding: 30px 24px 50px; //top right bottom left
  background: #fff;
  outline: none;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  
}

.restoreArchive {
  cursor: auto;
  &__close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 2.5px;

    img {
      width: 48px;
      height: 48px;
    }
    &__heading {
      width: 100%;
      color: $black-1000;
    }
    &__description,
    &__child {
      color: $black-800;
    }

    &__buttonsWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap:16px;
      button {
        width: 50%;
      }
      &__delete {
        background: $errors-700;
      }
    }
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

@media only screen and (max-width: $mobile) {
  .common-archive-restore-modal {
    width:90%;
    height: fit-content;
    transform: translate(-50%, -50%);
  }
}