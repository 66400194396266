.common-paper-custom {
  border-radius: 16px;
  background: #fff;
  cursor: auto;
  &--light {
    box-shadow: 0px 0px 16px 0px rgba(18, 18, 18, 0.1);
  }
  &--medium {
    box-shadow: 0px 8px 30px 0px rgba(18, 18, 18, 0.1);
  }
  &--high {
    box-shadow: 0px 8px 60px 0px rgba(76, 101, 169, 0.15);
  }
  &--no-box-shadow {
    box-shadow: none;
  }
}
