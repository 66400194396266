@import '../../../../assets/styles/index.scss';

.msteam {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  height: calc(100vh - 200px);
  margin-top: 16px;

  .connectStep:nth-child(5) {
    min-height: 100px !important;
  }

  .msteamContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__msteamHeaderView {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__header {
        color: $black-900;
      }
    }
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    margin-top: 10px;

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &__confirmBtn {
        background-color: $success-800;
        color: $white;
      }

      &__confirmBtnDisabled {
        background-color: $success-800;
        opacity: 0.5;
        color: $white;
        border-width: 0px;
      }
    }
  }
}

.noDataWrapper {
  margin-top: 16px;
}

.connectSteps {
  margin-top: 16px;
  padding: 24px 24px 40px 24px;

  &__stepTitle {
    color: $black-800;
  }

  &__descDiv {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 8px;
    color: $black-700;
  }
}

.key {
  font-family: Noto Sans;
  width: max-content;
}

.value {
  font-family: Noto Sans;
  word-wrap: break-word;
  width: max-content;
}

.msteamButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.msteam_loading {
  .apply-loader {
    @include loaderSkeleton;
    display: flex;
    padding: 0 5px;
  }
}
.webhookUrl {
  position: relative;
  left: 39px;
}

.container_webhookUrl {
  position: relative;
  top: -80px;
}
.webhookUrl_input {
  width: 500px;
}
.msteam.connectSteps {
  height: calc(100% - 100px);
}
.msteam.connectSteps.view {
  height: calc(100vh - 180px);
}

.ms_teams_status_container {
  background-color: $light-green-1;
  width: 115px;
  height: 30px;
  padding: 4px 16px;
  display: flex;
  gap: 8px;
  border-radius: 4px;
}
.ms_teams_status_container span {
  font-family: Noto Sans;
  color: $green_1;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  text-align: center;
}

.webhookUrl {
  position: relative;
  margin-left: -1.25rem;
  padding-left: 2rem;
}

.webhookUrl::before {
  content: '';
  position: absolute;
  left: 0;
  top: 10%;
  bottom: 25%;
  width: 1px;
  background-color: $black-300;
}

// @Style @ tablet
@media only screen and (max-width: $tablet) {
  .msteam {
    .connectStep {
      .description {
        width: inherit !important;
        padding-right: 10px;
      }
    }
  }
}

// @Style @ mobile
@media only screen and (max-width: $mobile) {
  .msteamContainer {
    &__msteamHeaderView {
      flex-direction: column !important;
      gap: 12px;
      &__header {
        width: 100%;
      }
      .msteamButtons {
        width: 100%;
        justify-content: flex-start;
        .disconnect-button {
          width: 140px !important;
        }
        .recofig-button {
          width: 145px !important;
        }
      }
    }
  }
  .msteam {
    .buttonWrapper {
      gap: 10px;
    }
    .connectStep {
      gap: 8px !important;
    }
    .container_webhookUrl {
      .webhookUrl {
        width: 95% !important;
        .webhookUrl_input {
          width: inherit !important;
        }
      }
    }
  }
  .msteam.connectSteps {
    height: fit-content;
  }
  .msteam.connectSteps.view {
    height: fit-content;
  }
}
