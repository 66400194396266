.form {
  padding-block: 12px;
  padding-inline: 23px;
}
.label_required:after {
  content: '*';
  color: #d1293d;
}
.input_fields {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #6e6e6e;
  height: 40px;
  padding: 12px 8px;
  padding-inline-end: 35px;
  margin-top: 6px;
  color: #1d1d1d;

  /* as per latest changes  */
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input_fields::placeholder {
  font-style: normal;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6e6e6e;
}

.input_fields:hover {
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
}

.input_fields:focus {
  outline: none; /* Remove default focus outline */
  border: 1px solid#33475B;
  box-shadow: none;
  outline-offset: 2px;
  padding: 12px 8px;
  padding-inline-end: 35px;
}

.eye_icon {
  position: absolute;
  top: 2rem;
  right: 1rem;
  cursor: pointer;
}

.form_button {
  width: 100%;
  height: 40px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  margin-block: 4px;
  letter-spacing: 0.06em;
}

.form_button:not([disabled]):hover {
  box-shadow: 0 1px 9px 0 rgba(114, 112, 112, 0.9);
}

.info_icon {
  position: absolute;
  top: 2rem;
  right: -1.5rem;
  cursor: pointer;
}
.info_circle {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
}

/* Custom styles for the checkbox */
.custom-checkbox-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.custom-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox {
  position: absolute;
  left: 0;
  top: -0.9rem;
  width: 1rem;
  height: 1rem;
  border: 1.9px solid;
  border-radius: 3px;
  background-color: white;
}

/* Style the checkmark when checked */
.custom-checkbox-input:checked + .custom-checkbox::before {
  content: '\2713'; /* Unicode checkmark character */
  font-size: 0.9rem; /* Adjust the size of the checkmark */
  margin: 3px;
  rotate: 45%;
  position: absolute;
  top: -0.3rem;
  left: 0.1rem;
  transform: rotate(11deg);
  z-index: 20;
}
/* Style the checkmark background */
.checkmark-background {
  position: absolute;
  background-color: #ffffff;
  padding: 6px;
  top: -1.1rem;
  left: 0.6rem;
  z-index: 10;
  opacity: 0; /* Hide the background by default */
}

/* Show the checkmark background when the checkbox is checked */
.custom-checkbox-input:checked + .custom-checkbox + .checkmark-background {
  opacity: 1; /* Make the background visible when the checkbox is checked */
}

.tooltip-container {
  position: absolute;
  top: -7.2rem;
  right: 0;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 38px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #374151;
}

.tooltip-content {
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  min-width: 150px; /* Adjust the width as needed */
}

/*Style @ Mobile */
@media screen and (max-width: 650px) {
  .info_icon {
    display: none;
  }
}

/*Style @ Tablet */
@media screen and (max-width: 768px) {
  .info_icon {
    display: none;
  }
}
