@import '../../../assets/styles/index.scss';

@mixin errorStatusFailed {
  color: $errors-700;
  background-color: $errors-100;
  border-color: $errors-700;
}

@mixin errorStatusPaused {
  color: $errors-600;
  background-color: $errors-100;
  border-color: $errors-600;
}

.accountStatusMessage {
  padding: 16px 0;
  border-radius: 6px;
  display: flex;
  border-width: 1px;
  border-color: $success-700;
  background-color: $success-100;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 56px;
}

.active {
  color: $success-900;
  background-color: $success-100;
  border-color: $success-900;
}
.failed {
  @include errorStatusFailed;
}

.inactive {
  @include errorStatusPaused;
}

.paused {
  @include errorStatusPaused;
}

.unreachable {
  @include errorStatusFailed;
}

.inprogress {
  color: $warning-700;
  background-color: $warning-100;
  border-color: $warning-700;
}
