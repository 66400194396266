@import '../../../../assets//styles//index.scss';

.accountContainer {
  width: 100%;
  padding-top: 1rem;
  min-height: calc(100vh - 190px);
  margin-top: 16px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 98px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__header {
        color: $black-900;
      }

      @media (min-width: 640px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__dataView {
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
          flex-direction: row;
        }
      }
    }
  }

  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.buttonView {
  right: 8px;
  width: 100%;

  @media (min-width: 640px) {
    width: fit-content;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}

.accountInfo {
  .apply-loader {
    @include loaderSkeleton;
    display: flex;
    padding: 0 5px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 35%);
  grid-template-rows: repeat(3, auto);
  column-gap: 120px;
  row-gap: 24px;

  &__grid-item {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__key {
      color: $black-900;
    }

    &__value {
      color: $black-800;
      padding: 12px 16px 12px 0px;
      max-width: 412px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// @Style @ Mobile
@media only screen and (max-width: $mobile) {
  .accountContainer {
    padding: 12px;
    &__form {
      &__content {
        &__header {
          width: 100%;
        }
      }
    }
    .typography__body-3__mobile {
      padding-inline: 1rem;
      text-align: center;
    }

    .cloud-account-container {
      flex-direction: column;
      padding-bottom: 3rem;
    }
    .buttonContainer {
      flex-direction: column;
      gap: 1rem;
      .accountInfoButtons {
        width: 100%;
        justify-content: flex-start;
      }
      .buttonView {
        display: flex;
        gap: 12px;
        .pauseButton,
        .editButton {
          width: 50px;
          span {
            display: none;
          }
        }
        .disconnectButton {
          width: 130px;
        }
      }
    }
    .cloud-account-container {
      &__list-item {
        &__value {
          width: 100%;
          word-break: break-all;
        }
      }
      .cloud-provider-logo {
        .grid-container__grid-item__value {
          padding: 0;
        }
      }
    }
  }
  .grid-container {
    grid-template-columns: repeat(1, 100%);
    row-gap: 14px;
  }
}

.cloud-account-container {
  display: flex;
  justify-content: space-between;
  margin-top: -17px;

  &__list-item {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;

    &__key {
      color: $black-900;
    }

    &__value {
      color: $black-800;
      padding: 12px 16px 12px 0px;
      max-width: 412px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
