@import '../../../assets/styles/globals/index.scss';

@mixin currentPasswordFormField {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .label {
    color: $black-900;
  }
  .input-field {
    @include inputField;
    padding: 10px 35px 11px 12px;
  }
  .mask-icon {
    cursor: pointer;
    position: absolute;
    @include verticalAlignAbs;
    right: 15px;
  }
}

.MuiModal-root#change-password-modal {
  display: flex;
}

.change-password-modal {
  width: 338px;
  background: #fff;
  border-radius: 16px;
  padding: 16px 12.5px 28px 23px;
  margin: 92px auto auto auto;
  outline: none;
  &__heading {
    @include flexCol;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    p {
      align-self: flex-start;
      color: $black-1000;
    }
    img {
      align-self: flex-end;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    padding: 0 16.5px 0 7px;
    gap: 40px;
    &__field {
      @include currentPasswordFormField;
      &__input {
        position: relative;
        .tooltip-icon {
          position: absolute;
          cursor: pointer;
          right: -23px;
          @include verticalAlignAbs;
        }
        .error-message {
          position: absolute;
          top: 100%;
          @include errorMessage;
        }
      }
    }
    &__confirm-password {
      margin-bottom: 10px;
    }
    &__new-password {
      position: relative;
      .input-field-tooltip {
        top: 28px;
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cancel {
        @include whiteButton;
        width: 134px;
      }
      .save {
        @include blueButton;
        width: 134px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .change-password-modal {
    width: 480px;
    padding: 24px 24px 39.5px 24px;
    &__form {
      gap: 24px;
      padding: 0;
      &__field {
        &__input {
          .tooltip-icon {
            display: none;
          }
        }
      }
      &__buttons {
        button {
          flex-basis: 50%;
        }
        column-gap: 16px;
        justify-content: space-between;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .change-password-modal__form__new-password .input-field-tooltip {
    display: none;
  }
  .tooltip-icon {
    display: none;
  }
}
.change-password-icon-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-bottom: 24px;
}
.change-password-icon-heading img {
  cursor: pointer;
}
