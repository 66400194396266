@mixin searchBar {
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  margin-bottom: 12px;
  &__input-field {
    position: relative;
    input {
      @include inputField;
      padding-left: 39px;
      margin-top: 0;
    }
    img {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &__buttons {
    display: flex;
    column-gap: 10px;
    align-self: flex-end;
    button {
      font-size: 12px;
      display: flex;
      gap: 6px;
      justify-content: center;
      align-items: center;
      width: 148px;
      white-space: nowrap;
    }
  }

  @media screen and (min-width: $tablet) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
    column-gap: 5px;
    &__input-field {
      width: 352px;
      input {
        margin-top: 0;
      }
    }
    &__buttons {
      button {
        font-size: 14px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    &__input-field {
      width: 625px;
      input {
        margin-top: 0;
      }
    }
  }
}
