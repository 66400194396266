@import '../../../assets/styles/index.scss';

@mixin showRowsPerPageEntries {
  font-family: Noto Sans;
  width: 141px;
  border-radius: 5px;
  background: #fff;
}

.common-table-paper {
  margin-bottom: 10px;
}
.MuiTableContainer-root {
  border-radius: 0;
  box-shadow: none;
  padding: 16px;
  overflow: auto;
  @include customScroll;
  &.common-table-container__loader {
    @include tableSkeletonLoader;
  }

  .MuiTable-root {
    .MuiTableHead-root {
      max-height: 39px;
      .MuiTableRow-root {
        .MuiTableCell-root {
          font-family: Noto Sans;
          color: $black-1000;
          background: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-top: 8.5px;
          padding-bottom: 8.5px;
          white-space: nowrap;
          border-bottom: 1px solid $primary-200;
          &.no-left-padding {
            padding-left: 0;
          }
          &.no-right-padding {
            padding-right: 0;
          }
          .cell-inner {
            display: flex;
            column-gap: 8px;
            align-items: center;

            span {
              color: $black-900;
            }

            &.text-center {
              justify-content: center;
            }
            span {
              white-space: nowrap;
            }
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
    .MuiTableBody-root {
      background: #fff;
      .MuiTableRow-root {
        max-height: 39px;
        transition: all 0.25s;
        cursor: pointer;
        .MuiTableCell-root {
          font-family: Noto Sans;
          color: $black-800;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-top: 8px;
          padding-bottom: 8px;
          white-space: nowrap;
          border-bottom: none;
          &.no-left-padding {
            padding-left: 0;
          }
          &.no-right-padding {
            padding-right: 0;
          }
          .item-no {
            color: $blue-1;
          }
          &.no-results {
            text-align: center;
            border-bottom: none;
            padding-top: 40px;
            .no-results__content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 20px;
              img {
                width: 103px;
              }
              p {
                color: $black-800;
              }
            }
          }
        }
        &:hover {
          background: $primary-100;
        }
        &.Mui-selected {
          background: $primary-200;
        }
        &.custom-table-no-results {
          &:hover {
            background: none;
          }
        }
      }
    }
  }
}
.common-table__pagination {
  display: flex;
  justify-content: center;
  &__rows-per-page {
    display: none;
    align-items: center;
    column-gap: 12px;
    span {
      font-family: Noto Sans;
      color: $black-1000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__select {
      position: relative;
      button {
        @include showRowsPerPageEntries;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        column-gap: 8px;
        align-items: center;
        padding: 10.5px 21px;
        border: 1px solid $primary-600;
        span {
          font-family: Noto Sans;
          color: $primary-600;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        img {
          transform: rotate(270deg);
        }
      }
      &__items {
        @include showRowsPerPageEntries;
        position: absolute;
        bottom: 0;
        border: 1px solid $primary-600;
        &__item {
          font-size: 14px;
          color: $black-1000;
          cursor: pointer;
          transition: all 0.25s;
          padding: 5px 7.5px 5px 7.5px;
          &:hover {
            background: $primary-200;
            border-radius: 5px;
          }
        }
      }
    }
  }
  &__page-numbers {
    display: flex;
    column-gap: 8px;
    align-items: center;
    .MuiStack-root {
      .MuiPagination-root {
        .MuiPagination-ul {
          display: flex;
          column-gap: 8px;
          align-items: center;
          li {
            display: contents;
            height: 40px;
            width: 40px;
            .MuiButtonBase-root {
              height: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              color: $black-800;
              font-family: Noto Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: #fff;
              border: none;
              &.Mui-selected {
                font-weight: 600;
                color: #fff;
                background: $primary-700;
              }
              .MuiTouchRipple-root {
                display: none;
              }
              .MuiSvgIcon-root {
                path {
                  fill: $black-1000;
                }
              }
            }
            .Mui-disabled.MuiPaginationItem-root {
              background: $light-grey-4;
            }
            .MuiPaginationItem-ellipsis {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .common-table-paper {
    margin-bottom: 30px;
  }
  .MuiTableContainer-root {
    @include customScroll;
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            padding-top: 16px;
            padding-bottom: 16px;
          }
        }
      }
      .MuiTableBody-root {
        background: #fff;
        .MuiTableRow-root {
          max-height: 47px;
          .MuiTableCell-root {
            padding-top: 16px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
  .common-table__pagination {
    justify-content: space-between;
    &__rows-per-page {
      display: flex;
      span {
        font-size: 16px;
      }
    }
    &__page-numbers {
      img,
      span {
        font-size: 14px;
        height: 32px;
        width: 32px;
      }
      .MuiStack-root {
        .MuiPagination-root {
          .MuiPagination-ul {
            li {
              .MuiButtonBase-root {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .common-table-paper {
    margin-bottom: 24px;
  }
  .common-table__pagination {
    justify-content: space-between;
    &__page-numbers {
      img,
      span {
        font-size: 14px;
        height: 30px;
        width: 32px;
      }
    }
  }
}
