@import '../../../assets/styles/index.scss';

.search-form {
  @include flexRow;
  flex-direction: row !important;
  align-items: center;
  column-gap: 16px;

  &--is-loading {
    .apply-loader {
      @include loaderSkeleton;
    }
  }

  &__input-field {
    position: relative;
    flex: 1;
    input {
      @include inputField;
      padding-left: 39px;
      margin-top: 0;
    }
    img {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__submit-button {
    display: none;
    min-width: 150px;
  }

  @media screen and (min-width: $tablet) {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 5px;
    &__input-field {
      input {
        margin-top: 0;
      }
    }

    &__submit-button {
      display: block;
    }
  }

  @media screen and (min-width: $desktop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    &__input-field {
      input {
        margin-top: 0;
      }
    }
  }
}
