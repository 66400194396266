@import '../../../assets/styles/index.scss';

.cardWrapper {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 120px);
  background-color: $white;
  /* Replace with desired background color */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 40px;
  font-family: Noto Sans;
  overflow: visible;

  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/png/cardBGWave.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.6;
  }
}

.accountId {
  color: $primary-700;
}

.awsForm {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  background: transparent;

  &__formWrapper {
    font-size: normal;
    white-space: normal;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__input {
      background: transparent;
    }
  }
}

.policies-styling {
  @include customScroll;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
}
.aws_btn,
.awsForm__formWrapper__disabledBtn {
  margin-top: 10px;
}
.cloud-accounts__connect-account-form {
  @media screen and (min-width: $desktop) {
  }
}

@media (min-width: 640px) {
  .cardWrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .awsForm {
    .__formWrapper {
      flex-direction: row;
      gap: 1rem;
    }
  }
}

@media (min-width: 1024px) {
  .cardWrapper {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    flex-direction: row;
  }

  .awsForm {
    display: flex;
    flex-direction: column;

    &__formWrapper {
      flex-direction: column;
      gap: 32px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .awsForm__formWrapper {
    gap: 5px;
  }
}
.aws-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 64px;
}
.input-inner-container {
  width: 75%;
}

@media screen and (max-width: $tablet) {
  .aws-input-container {
    flex-direction: column;
    gap: 16px;
  }
  .input-inner-container {
    width: 100%;
  }
}
