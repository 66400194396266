$black-100: #f2f2f2;
$black-200: #dadada;
$black-300: #bfbfbf;
$black-400: #a4a4a4;
$black-500: #898989;
$black-600: #6e6e6e;
$black-700: #535353;
$black-800: #383838;
$black-900: #1d1d1d;
$black-1000: #121212;

$primary-100: #f2f7fd;
$primary-200: #e3ecfb;
$primary-300: #c1daf6;
$primary-400: #8bbbee;
$primary-500: #4c98e4;
$primary-600: #257ad2;
$primary-700: #165baa;
$primary-800: #144c90;
$primary-900: #144278;
$primary-1000: #163864;
$primary-1100: #0f2442;
$primary-1200: #e5e5ef;
$primary-1300: #4a3aff;

$errors-100: #fef2f2;
$errors-200: #fee2e2;
$errors-300: #fecaca;
$errors-400: #fca5a5;
$errors-500: #f87171;
$errors-600: #ef4444;
$error-600-light: #f04d4d;
$errors-700: #dc2626;
$errors-800: #b91c1c;
$errors-900: #991b1b;
$errors-1000: #7f1d1d;

$success-100: #ecfdf5;
$success-200: #d1fae5;
$success-300: #a7f3d0;
$success-400: #6ee7b7;
$success-500: #34d399;
$success-600: #10b981;
$success-700: #059669;
$success-800: #047857;
$success-900: #065f46;
$success-1000: #064e3b;

$warning-100: #fef4e8;
$warning-200: #fee2c3;
$warning-300: #fec78a;
$warning-400: #fda747;
$warning-500: #fa8e15;
$warning-600: #ea8008;
$warning-700: #ca6d04;
$warning-800: #a15907;
$warning-900: #854d0e;
$warning-1000: #714412;

$layout-100: #fcfcfc;

$gradient-100: rgba(161, 165, 255, 0.3);
$gradient-200: #eeefff68;
