@import '../../assets/styles/globals/index.scss';

@mixin profile-block {
  border-radius: 10px;
  background: #fff;
  min-width: 250px;
}

@mixin information-label {
  color: $black-900;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &--is-required {
    &::after {
      content: '*';
      color: $red-toast-stroke;
    }
  }
}

@mixin information-value {
  @include information-label;
  color: $black-800;
  font-weight: 400;
}

@mixin information-box {
  padding: 19px 20px;
  border-radius: 10px;
  background: #fff;
}

@mixin image-dimensions {
  width: 86px;
  height: 86px;
}

@mixin image-dimensions-desktop {
  width: 126px;
  height: 126px;
}

@mixin profile-buttons {
  display: flex;
  justify-content: space-between;
  column-gap: 13px;
  .cancel {
    @include whiteButton;
    width: 100%;
  }
  .save {
    @include blueButton;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.my-profile {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  &--is-loading {
    .apply-loader {
      @include loaderSkeleton;
    }
  }
  &__main {
    @include profile-block;
    &__head {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 61px 10px 32px 10px;
      gap: 8px;
      margin-bottom: 14px;

      &__image-block {
        @include image-dimensions;
        position: relative;

        border-radius: 50%;
        .profile-image-text-outer {
          @include image-dimensions;
          background: $primary-300;
          border-radius: 50%;
          display: flex;
          .profile-image-text-inner {
            color: $black-800;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: auto;
          }
          img {
            @include image-dimensions;
            border-radius: 50%;
            object-fit: contain;
          }
        }
        .upload-profile-image {
          position: absolute;
          @include flexJustifyCenter;
          @include flexAlignItemsCenter;
          height: 32px;
          width: 32px;
          cursor: pointer;
          bottom: 0;
          right: 0;
          border-radius: 50%;
          transition: all 0.25s;
          background: white;
          svg {
            height: 18px;
            width: 18px;
          }
          &:hover {
            box-shadow:
              0.5px 0.5px 5px 1px rgba(0, 0, 0, 0.25),
              -0.5px -0.5px 5px 1px rgba(0, 0, 0, 0.25);
          }
        }
      }
      &__name {
        color: $black-1000;
        font-family: 'Noto Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        word-break: break-all;
      }
      &__email {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span {
          color: $black-800;
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 5px;
          word-break: break-all;
        }
        img,
        svg {
          display: inline-block;
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
      }
    }
    &__user-data {
      width: 100%;
      &__personal-information {
        @include information-box;
        padding: 19px 20px 19px 20px;
        margin-bottom: 14px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        &__fields {
          display: grid;
          grid-template-columns: 100%;
          gap: 32px;
          &--is-edit {
            gap: 29px;
          }
          &__field {
            display: flex;
            flex-direction: column;
            .label {
              @include information-label;
            }
            .value {
              @include information-value;
              margin-top: 8px;
              word-break: break-all;
              width: 100%;
            }
            input {
              @include inputField;
              margin-top: 10px;
            }
            .error-message {
              @include errorMessage;
            }
          }
        }
        .buttons-desktop {
          display: none;
        }
      }
      &__professional-information {
        @include information-box;
        padding: 19px 20px 26px 20px;
        margin-bottom: 14px;
        &__title {
          @include information-label;
          margin-bottom: 20px;
        }
        &__fields {
          display: flex;
          flex-direction: column;
          gap: 28px;
          &--is-edit {
            gap: 18px;
          }
          &__field {
            display: flex;
            flex-direction: column;
            .label {
              @include information-label;
            }
            .value {
              @include information-value;
              margin-top: 14px;
              word-break: break-all;
              width: 100%;
              &.onboardedDate {
                word-break: keep-all;
                width: 100%;
              }
            }
            input {
              @include inputField;
              margin-top: 10px;
            }
            .error-message {
              @include errorMessage;
            }
          }
        }
      }
      &__buttons {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        row-gap: 19px;
        &__title {
          @include information-label;
        }
        @include profile-buttons;
      }
      &__edit-button {
        @include whiteButton;
        cursor: pointer;
        border-radius: 6px;
        width: 100%;
        padding: 10.5px 21px;
        height: 40px;
        display: flex;
        justify-content: center;
        gap: 8px;
        align-items: center;
        color: $primary-600;
        img {
          height: 16px;
          width: 16px;
        }
        span {
          @include information-label;
          color: $primary-900;
          font-size: 15px;
          font-weight: 500;
        }
        &:disabled {
          border: 1px solid $light-grey-1;
          img,
          span {
            filter: contrast(0.1);
          }
          &:hover {
            box-shadow: none;
          }
        }
      }
      &__deactivate-button {
        @include lightRedButton;
        height: 40px;
        width: 100%;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        &:disabled {
          opacity: 50%;
        }
      }
    }
  }

  &__password {
    @include information-box;
    padding: 16px;
    margin-bottom: 32px;
    &__fields {
      display: flex;
      flex-direction: column;
      &__field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          @include information-label;
          &.password {
            font-size: 16px;
            font-weight: 400;
            color: $black-1000;
          }
        }
        .change-password {
          @include blueButton;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          padding: 10.5px 21px;
          width: 180px;
          &:disabled {
            @include disabledButton;
            cursor: not-allowed;
          }
          img {
            margin-right: 5px;
          }
          span {
            color: #fff;
          }
        }
      }
    }
  }
  &__access-control {
    &--is-edit {
      padding: 19px 20px 13px 20px;
    }
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;
      &.has-edit-button {
        margin-bottom: 8px;
      }
      &__heading {
        @include information-label;
      }
      &__edit-button {
        @include whiteButton;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        width: 171px;
        &:disabled {
          background: $light-grey-1;
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 20px;
      margin-bottom: 20px;
      &__groups,
      &__role {
        display: flex;
        flex-direction: column;
        gap: 14px;
        .label {
          @include information-label;
        }
        .value {
          @include information-value;
          word-break: break-all;
          width: 100%;
        }
      }
      &__groups {
        flex: 1;
        &__items {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: fit-content;
          &__item {
            border-radius: 4px;
            padding: 2px 10px;
            width: fit-content;
          }
          &__view-more-list {
            position: absolute;
            bottom: 0;
            left: calc(100% + 25px);
          }
          &__view-more-button {
            font-family: Noto Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
            user-select: none;
          }
        }
      }
      &__role {
        flex-basis: 32%;
      }
    }
    &__buttons {
      @include profile-buttons;
      display: none;
    }
  }
  &__profile-image-preview {
    position: fixed;
    left: 10000px;
    max-width: max-content;
    max-height: max-content;
  }
}

@media screen and (min-width: $tablet) {
  .my-profile {
    flex-direction: row;
    gap: 32px;
    margin: 20px 0px 0px 0px;
    &__main {
      flex-basis: 23.4%;
      flex-grow: 0;
      &__head {
        margin-bottom: 0;
        padding: 61px 10px 32px 10px;
      }
      &__user-data {
        &__personal-information {
          padding: 19px 15px 14px 15px;
          &--is-edit {
            &__title-buttons {
              margin-bottom: 27px;
            }
          }
          &__fields {
            gap: 9px;
          }
        }
        &__professional-information {
          padding: 19px 20px 26px 20px;
          &__fields {
            gap: 5px;
          }
        }
        &__buttons {
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0;
          &__title {
            @include information-label;
          }
          @include profile-buttons;
          .cancel {
            padding: 6px 10px;
          }
          .save {
            padding: 6px 10px;
          }
        }
      }
    }
    &__details {
      flex: 1;
    }

    &__access-control {
      &--is-edit {
        padding: 19px 20px 16px 20px;
      }
      &__content {
        flex-direction: row;
        margin-bottom: 17px;
        gap: 29px;
        &__groups,
        &__role {
          flex-basis: 50%;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .my-profile {
    margin: 0;
    &__main {
      &__head {
        padding: 23px 24px 0px 24px;
        flex-basis: 27%;
        border-radius: 0;
        &__image-block {
          @include image-dimensions-desktop;
          .profile-image-text-outer {
            @include image-dimensions-desktop;
            img {
              @include image-dimensions-desktop;
            }
            .profile-image-text-inner {
              font-size: 25px;
            }
          }
        }
        &__name {
          font-size: 16px;
          word-break: break-all;
          width: 100%;
          text-align: center;
        }
        &__email {
          span {
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }

      &__user-data {
        border-radius: 0;
        &__personal-information {
          padding: 24px;
          &--is-edit {
            padding: 24px 20px 19px 20px;
            .buttons-desktop {
              @include profile-buttons;
            }
          }
          &__fields {
            column-gap: 29px;
            row-gap: 5px;
            &--is-edit {
              flex-direction: row;
            }
          }
        }
        &__professional-information {
          padding: 19px 20px 15px 20px;
          &--is-edit {
            padding: 19px 20px 5px 20px;
          }
          &__fields {
            flex-direction: row;
            gap: 29px;
            &__field {
              flex-basis: 50%;
            }
          }
        }
        &__buttons {
          .cancel {
            padding: 6px 10px;
          }
          .save {
            padding: 6px 10px;
          }
        }
      }
    }
    &__details {
      flex-basis: 76%;
      @include flexCol;
      @include customScroll;
      form {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    &__access-control {
      flex-basis: 100%;
      padding: 19px 0px 0px 0px;
      &__content {
        margin-bottom: 0;
      }
      &--is-edit {
        padding: 19px 20px 11px 20px;
      }
      &__heading {
        margin-bottom: 14px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .my-profile {
    display: grid;
  }
  .my-profile__password {
    margin-top: 32px;
  }
}
