@import '../../assets/styles/index.scss';

.signIn {
  display: flex;
  flex-direction: column;
  gap: 14px;
  &__form {
    display: flex;
    flex-direction: column;
    gap: 14px;
    cursor: auto;

    &__field {
      &__password {
        color: $black-900;
      }
      &__wrapper {
        &__error {
          color: $errors-800;
          padding-top: 5px;
        }
        &__link {
          color: $primary-600;
          width: fit-content;
          @extend .cursor-pointer;
        }
      }
    }

    &__button {
      width: 100%;
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;

    &__text {
      color: $black-900;
    }
    &__link {
      color: $primary-600;
      @extend .cursor-pointer;
    }
  }
}
