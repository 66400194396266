.img_circle {
  transition: box-shadow 0.3s ease;
  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../../assets/images/svg/edit_profife_img.svg);
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      background-color: #00000040;
      border-radius: 50%;
      transition: opacity 0.3s ease;
      z-index: 99999;
    }
  }
}
