@import '../../../assets/styles/globals/index.scss';

.footer {
  display: flex;
  justify-content: space-between;
  padding: 6px 20px 7px 13px;
  z-index: 100;
  &__item {
    color: $black-900;

    &__link {
      cursor: pointer;
      &:hover {
        color: #2264e5;
      }
    }
  }
}
.footer .vertical-bar {
  display: none;
  margin: 0 13px;
}

/* Tablet styles */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .footer {
    justify-content: flex-end;
    padding: 6px 56px 7px 56px;
    z-index: 100;
  }
  .footer .vertical-bar {
    display: block;
  }
}

/* Desktop styles */
@media only screen and (min-width: 1023px) {
  .footer {
    margin-top: auto; /* Push the footer to the bottom */
    display: flex;
    justify-content: flex-end;
    padding: 6px 56px 7px 56px;
    z-index: 100;
  }
  .footer .vertical-bar {
    display: block;
  }
}

/* Mobile styles */
@media screen and (max-width: 600px) {
  .footer {
    justify-content: center;
  }
}
