@import '../../../../assets/styles/index.scss';

.edit-group-permissions-container {
  @include flexCol;
  row-gap: 24px;
  margin: 56px 16px 16px 16px;
  &__permissions {
    @include flexCol;
    row-gap: 24px;
  }
  &__navigation {
    display: flex;
    justify-content: center;
    background: #fff;
    column-gap: 24px;
    padding-bottom: 16px;
    &__backward {
      display: flex;
      column-gap: 18px;
      .cancel {
        @include whiteButton;
        width: 98px;
      }
      .goback {
        @include whiteButton;
        width: 75px;
        border: none;
        background: none;
        &:hover {
          box-shadow: none;
          background: $light-blue-3;
        }
      }
    }
    &__forward {
      .next {
        @include blueButton;
        width: 98px;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .edit-group-permissions-container {
    &__navigation {
      &__backward {
        column-gap: 13px;
        .cancel {
          width: 200px;
        }
        .goback {
          width: 200px;
        }
      }
      &__forward {
        .next {
          width: 200px;
        }
      }
    }
  }
}
