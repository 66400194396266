@mixin tableSkeletonLoader {
  @keyframes loadingAnimation {
    0% {
      background-position: 0%;
    }
    25% {
      background-position: 50%;
    }
    50% {
      background-position: 100%;
    }
    75% {
      background-position: 50%;
    }
    100% {
      background-position: 0%;
    }
  }
  overflow: hidden;
  tr {
    &:hover {
      background: none !important;
    }
  }
  th,
  td {
    background: none !important;
    .loader-animation {
      border-radius: 6px;
      height: 22px;
      background-image: linear-gradient(
        90deg,
        #f1efef -24.18%,
        #f9f8f8 50.26%,
        #e7e5e5 114.84%
      ) !important;
      animation: loadingAnimation 3s infinite linear;
      background-size: 400% 100%;
    }
    border-bottom: none;
  }
}

@mixin showRowsPerPageEntries {
  font-family: Noto Sans;
  width: 114px;
  border-radius: 5px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid $light-grey-1;
  box-shadow:
    1px 6px 11px 0px rgba(0, 0, 0, 0.07),
    0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}
