@import '../../assets/styles/index.scss';

@mixin textStyles {
  color: $primary;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.not-found-container {
  height: calc(100dvh - 150px);
  display: flex;
  justify-content: center;
  align-items: center;
  .not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 31px;
    width: 90%;
    &__error-code {
      @include textStyles;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 128px;
      align-self: flex-start;
    }
    &__content {
      &__heading {
        @include textStyles;
        font-size: 24px;
        margin-bottom: 18px;
      }
      &__description {
        @include textStyles;
        font-family: Noto Sans;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 32px;
      }
      &__buttons {
        display: flex;
        flex-wrap: wrap;
        row-gap: 14px;
        column-gap: 14px;
        &__button {
          width: 161px !important;
          height: 55px !important;
          font-family: Noto Sans;
          font-size: 13px;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .not-found-container {
    .not-found {
      height: 211px;
      flex-direction: row;
      column-gap: 31px;
      &__content {
        width: 336px;
      }
    }
  }
}
