@tailwind base;
@tailwind components;
@tailwind utilities;

::-ms-reveal {
  display: none;
}

.Toastify .Toastify__toast-container {
  width: 441px;
}

.Toastify__toast-body {
  /* Fixes the following bug- Close button in toast message if the message is too long. */
  flex-basis: 100% !important;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Toastify .Toastify__toast-container {
    width: 441px;
  }
}
@media screen and (max-width: 767px) {
  .Toastify .Toastify__toast-container {
    width: 330px;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.removeScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.removeScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.box_shadow {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}

/* Hide input field number arrows */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* for mozilla firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* bos shadow for policies card */
.box_shadow_policies {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.aws_image {
  background: lightgray -169.863px -57.028px / 131.153% 260.55% no-repeat;
}

.buttons_card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  margin-block: 4px;
  letter-spacing: 0.01em;
}

.button:not([disabled]):hover {
  box-shadow: 0 1px 9px 0 rgba(114, 112, 112, 0.9);
}

@keyframes toastMessageEnterAnimationKeyFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes toastMessageExitAnimationKeyFrames {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.global_toast_message_enter_animation {
  animation: toastMessageEnterAnimationKeyFrames 0.25s ease-in both;
}

.global_toast_message_exit_animation {
  animation: toastMessageExitAnimationKeyFrames 0.35s ease-in both;
}
