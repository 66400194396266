@import '../../assets/styles/index.scss';

.rotate {
  display: inline-block;
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cloud-accounts {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  &--is-loading {
    border-radius: 10px;
    background: #fff;
    .apply-loader {
      @include loaderSkeleton;
    }
  }
  &__search {
    display: flex;
    flex-direction: column;
    row-gap: 11px;
    margin-bottom: 12px;
    &__input-field {
      position: relative;
      @include flexRow;
      @include flexAlignItemsCenter;
      column-gap: 16px;
      input {
        @include inputField;
        padding-left: 39px;
        margin-top: 0;
      }
      img {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
    &__buttons {
      display: flex;
      column-gap: 10px;
      align-self: flex-end;
      button {
        font-size: 12px;
        display: flex;
        gap: 6px;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
      .refresh-table {
        @include whiteButton;
        font-size: 14px;
        color: $primary;
        padding: 0 21px;
        min-width: 118px;
      }
      .connect-aws {
        @include blueButton;
        padding: 0 21px;
        &--is-disabled {
          opacity: 0.6;
        }
      }
    }
  }
  &__table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .common-table-container {
      &__table {
        &__head {
          &__row {
            &__cell {
              .configure-notifications-head,
              .actions-head {
                justify-content: center;
              }
            }
          }
        }
        &__body {
          &__row {
            &__cell {
              .account-name {
                color: $primary-600;
                font-family: Noto Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                cursor: pointer;
                &--is-disabled {
                  color: $normal-text;
                }
              }
              .configure-notifications-container,
              .actions-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .cloud-accounts {
    &__search {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;
      column-gap: 5px;
      &__input-field {
        input {
          margin-top: 0;
        }
      }
      &__buttons {
        button {
          font-size: 14px;
        }
        .refresh-table {
          width: 95px;
        }
      }
    }
  }
}
.refresh-table span {
  color: $primary-600;
}
@media screen and (min-width: $desktop) {
  .cloud-accounts {
    &__search {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__search-users {
        width: 566px;
      }

      margin-bottom: 15px;
      &__input-field {
        width: 625px;
        input {
          width: 400px;
        }
        input {
          margin-top: 0;
        }
      }
    }
  }
}

.disconnect_account {
  .secondary_button {
    @include whiteButton;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .primary_button {
    @include blueButton;
    background-color: #33475b;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.welcomeMessage {
  .connect {
    padding: 10px;
    width: max-content;
  }
}
.cell-inner.center {
  justify-content: center;
}
.account_tenantid {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: $mobile) {
  .cloud-accounts__search {
    margin-bottom: 30px;
  }
  .search_btn_hidden {
    display: none;
  }
  .cloud-accounts__search__input-field {
    display: block;
  }
}

@media (max-width: $tablet) {
  .cloud-accounts__search {
    flex-direction: column-reverse;
  }
  .cloud-accounts__search__buttons {
    align-self: flex-start;
  }
  .cloud-accounts__search__input-field {
    justify-content: space-between;
  }
  .cloud-accounts_form_container {
    width: 100%;
  }
}
